import { any } from "ramda"
import { isValidPhoneNumber } from "react-phone-number-input"
import {
  getValidationError,
  getValidationSuccess,
  isNilOrEmpty,
  isNilOrEmptyOrBlank,
  localizationKey,
  localized,
} from "js/includes/common/utils"
import {
  EMAIL_REGEX,
  MAX_FLOAT_VALUE,
  MAX_INTEGER_VALUE,
  MAX_WYSIWYG_HTML_LENGTH,
  MIN_FLOAT_VALUE,
  MIN_INTEGER_VALUE,
} from "js/includes/common/_constants"
import {
  containsOneInteger,
  containsOneLowercaseLetter,
  containsOneUppercaseLetter,
  isDecimalWithinRange,
  isIntegerWithinRange,
  isValidInteger,
  isValidIpAddress,
  isValidNumber,
  isValidSecureIp,
  isValidSecureURL,
  validations as _validations,
} from "js/includes/common/utils/validations"
import { disabledDays } from "js/includes/components/CustomFields/common"
import { parseAndValidateDate, parseDateErrorResponse } from "@ninjaone/utils/helpers"
import { formatDate } from "react-day-picker/moment"

const duplicateMessageLocalizationMapper = {
  aJobWithThatName: localizationKey("A job with this name already exists"),
  documentName: localizationKey("A Document with this name already exists"),
  name: localizationKey("Name already exists"),
  label: localizationKey("Label already exists"),
}
const getLocalizedDuplicateMessage = key =>
  duplicateMessageLocalizationMapper.hasOwnProperty(key)
    ? localized(duplicateMessageLocalizationMapper[key])
    : localized("{{textField}} already exists", { textField: localized(key) })

export const validateTextField = (key, value, items = [], id) => {
  const isNew = !id

  if (isNilOrEmptyOrBlank(value)) {
    return {
      success: false,
      message: localized("Required"),
    }
  }

  const isDuplicate = any(item => {
    const exists = item?.[key]?.toLowerCase() === value.trim().toLowerCase()

    if (isNew) {
      return exists
    }

    const fieldId = item.id || item.uiElementUid || item.tempId
    const isNotCurrentField = id !== fieldId

    return isNotCurrentField && exists
  }, items)

  if (isDuplicate) {
    return {
      success: false,
      message: getLocalizedDuplicateMessage(key),
    }
  }

  return { success: true, message: "" }
}

function isValidUrl(urlString) {
  if (isNilOrEmpty(urlString)) return true

  try {
    new URL(urlString)
    return true
  } catch {
    return false
  }
}

export const validations = {
  required: _validations.required,

  url(value) {
    return {
      success: (isNilOrEmpty(value) || isValidSecureURL(value) || isValidSecureIp(value)) && isValidUrl(value),
    }
  },
  email(value) {
    return {
      success: isNilOrEmpty(value) || EMAIL_REGEX.test(value),
    }
  },
  phone(value) {
    return {
      success: isNilOrEmpty(value) || isValidPhoneNumber(value),
    }
  },
  totpSecret(value) {
    if (isNilOrEmpty(value)) {
      return getValidationSuccess()
    }

    if (value.length < 6) {
      return getValidationError(localizationKey("Must contain at least six characters"))
    }

    if (value.length > 200) {
      return getValidationError(localizationKey("Exceeds max character limit of {{limit}}", { limit: 200 }))
    }

    return getValidationSuccess()
  },
  ipAddress(field) {
    return value => {
      if (isNilOrEmpty(value)) {
        return getValidationSuccess()
      }

      if (!isValidIpAddress(value, field.advancedSettings?.ipAddressType)) {
        switch (field.advancedSettings?.ipAddressType) {
          case "IPV4":
            return getValidationError(localizationKey("Invalid IPv4 address"))
          case "IPV6":
            return getValidationError(localizationKey("Invalid IPv6 address"))
          default:
            return getValidationError(localizationKey("Invalid IP address"))
        }
      }

      return getValidationSuccess()
    }
  },
  integer(field) {
    return value => {
      if (isNilOrEmpty(value)) {
        return getValidationSuccess()
      }

      if (!isValidInteger(value)) {
        return getValidationError(localizationKey("Invalid value"))
      }

      if (
        !isIntegerWithinRange(
          value,
          field.advancedSettings?.numericRange?.min,
          field.advancedSettings?.numericRange?.max,
        )
      ) {
        return getValidationError(localizationKey("Value must be within {{min}} and {{max}}"), {
          min: field.advancedSettings?.numericRange?.min ?? MIN_INTEGER_VALUE,
          max: field.advancedSettings?.numericRange?.max ?? MAX_INTEGER_VALUE,
        })
      }

      return getValidationSuccess()
    }
  },
  decimal(field) {
    return value => {
      if (isNilOrEmpty(value)) {
        return getValidationSuccess()
      }

      if (!isValidNumber(value)) {
        return getValidationError(localizationKey("Invalid value"))
      }

      if (
        !isDecimalWithinRange(
          value,
          field.advancedSettings?.numericRange?.min,
          field.advancedSettings?.numericRange?.max,
        )
      ) {
        return getValidationError(localizationKey("Value must be within {{min}} and {{max}}"), {
          min: field.advancedSettings?.numericRange?.min ?? MIN_FLOAT_VALUE,
          max: field.advancedSettings?.numericRange?.max ?? MAX_FLOAT_VALUE,
        })
      }

      return getValidationSuccess()
    }
  },
  secureText(field) {
    return value => {
      if (isNilOrEmpty(value)) {
        return getValidationSuccess()
      }

      const isValuePlaceholder = value?.startsWith("SECURE_VALUE_PLACEHOLDER")

      const {
        mustContainOneInteger,
        mustContainOneUppercaseLetter,
        mustContainOneLowercaseLetter,
        greaterOrEqualThanSixCharacters,
      } = field.advancedSettings.complexityRules

      const { maxCharacters } = field.advancedSettings

      if (mustContainOneInteger && !containsOneInteger(value)) {
        return getValidationError(localizationKey("Must contain one integer"))
      }

      if (mustContainOneLowercaseLetter && !containsOneLowercaseLetter(value)) {
        return getValidationError(localizationKey("Must contain one lowercase letter"))
      }

      if (mustContainOneUppercaseLetter && !containsOneUppercaseLetter(value)) {
        return getValidationError(localizationKey("Must contain one uppercase letter"))
      }

      if (greaterOrEqualThanSixCharacters && value?.length < 6) {
        return getValidationError(localizationKey("Must contain at least six characters"))
      }

      if (value?.length > maxCharacters && !isValuePlaceholder) {
        return getValidationError(localizationKey("Exceeds max character limit of {{limit}}"), { limit: maxCharacters })
      }

      return getValidationSuccess()
    }
  },
  wysiwyg(field) {
    return value => {
      if (field.required && isNilOrEmpty(value?.html?.trim?.())) {
        return { success: false, message: localized("Required") }
      }

      if (isNilOrEmpty(value)) {
        return getValidationSuccess()
      }

      if (value.html.length > MAX_WYSIWYG_HTML_LENGTH) {
        return getValidationError(localizationKey("Exceeds max character limit of {{limit}}"), {
          limit: MAX_WYSIWYG_HTML_LENGTH,
        })
      }

      return getValidationSuccess()
    }
  },
  date(field) {
    return value => {
      if (isNilOrEmpty(value)) {
        return getValidationSuccess()
      }

      const validation = parseAndValidateDate({
        value: formatDate(new Date(value), "L", "en-US"),
        disabledDays: disabledDays(field.advancedSettings),
        localeFormat: "MM/DD/YYYY",
      })
      if (!validation.error) return getValidationSuccess()

      return getValidationError(localizationKey(parseDateErrorResponse(validation.error)))
    }
  },
}

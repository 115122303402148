import { css } from "@emotion/react"
import { sizer } from "@ninjaone/utils"
import SubNavItemContent from "./SubNavItemContent"

const noticeStyle = theme => css`
  padding-left: ${sizer(3)};
  padding-right: ${sizer(3)};
  margin-bottom: 0;

  && {
    color: ${theme.colorThemeTextWeak};
  }
`

const SubNavNotice = ({ text }) => {
  return (
    <div css={noticeStyle}>
      <SubNavItemContent name={text} textSize="xs" />
    </div>
  )
}

export default SubNavNotice

import { memo } from "react"
import styled from "@emotion/styled"
import { AlertMessage, Modal, Text, ProgressBar, Body } from "@ninjaone/components"
import { VARIANTS } from "@ninjaone/components/src/Button"
import { CircleCheckIcon, CircleExclamationIcon, ExclamationTriangle } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import Loading from "js/includes/components/Loading"
import {
  localized,
  localizationKey,
  ninjaReportError,
  showSuccessMessage,
  showErrorMessage,
  user,
  noop,
  dateTime,
} from "js/includes/common/utils"
import { canCancelLockhartJob, isLockhartEnabled } from "js/includes/common/backup"
import { getBackupJob, submitJob } from "js/includes/common/backup"
import { Flex } from "js/includes/components/Styled"

const showRetryOption = async ({ nodeId, isUp }) => {
  return isUp && (await isLockhartEnabled({ nodeId }))
}

const runBackupPlan = async ({ nodeId, nodeName, planId, planName, refreshCallback = noop }) => {
  try {
    const backupJob = getBackupJob({ nodeId, nodeName, planId, planName })
    await submitJob([backupJob])
    showSuccessMessage(localized("Backup job created successfully"))
    refreshCallback()
  } catch (error) {
    showErrorMessage(localized("Error running backup job"))
    ninjaReportError(error)
  }
}

const StyledDivider = styled.div`
  height: 1px;
  border: ${({ theme }) => `1px solid ${theme.colorBorderWeakest}`};
`

const DetailsSection = ({ title, icon, name }) => {
  return (
    <Flex flexDirection="column" gap={tokens.spacing[2]}>
      <Text type="body" fontWeight={tokens.typography.fontWeight.medium}>
        {title}
      </Text>
      <Flex gap={tokens.spacing[2]} alignItems="center">
        {icon}
        {typeof name === "function" ? name() : <Body>{name}</Body>}
      </Flex>
    </Flex>
  )
}

const RunningJobModal = ({
  cancelButton,
  clearButton,
  node,
  planUID,
  planName,
  refreshCallback,
  actionType,
  isPreparing,
  jobCompleted,
  jobCompletedWithWarnings,
  jobFailed,
  jobCancelled,
  error,
  loading,
  detailsSections,
  createTime,
  percentage,
  jobTotalFiles,
  modalTitle,
  preparingJobText,
  processedDataText,
  filesScannedText,
  jobCompletedText,
  jobCompletedWithWarningsText,
  unmount,
}) => {
  const refreshButton = {
    labelToken: localizationKey("Refresh"),
    variant: VARIANTS.PRIMARY,
    onClick: () => window.location.reload(),
  }

  const retryButton = {
    labelToken: localizationKey("Retry"),
    variant: VARIANTS.PRIMARY,
    onClick: () =>
      runBackupPlan({
        nodeId: node.id,
        nodeName: node.name,
        planId: planUID,
        planName: planName,
        refreshCallback,
      }),
  }

  const closeButton = {
    labelToken: localizationKey("Close"),
    variant: VARIANTS.SECONDARY,
    onClick: unmount,
  }

  const continueButton = {
    labelToken: localizationKey("Close and continue"),
    variant: VARIANTS.PRIMARY,
    onClick: unmount,
  }

  const getModalButtons = () => {
    // Error getting details - Refresh
    if (!actionType) {
      return [refreshButton]
    }
    // Success - Close
    if (jobCompleted) {
      return [closeButton]
    }
    // Connection error - Cancel backup, Refresh
    if (error) {
      return canCancelLockhartJob(actionType) ? [cancelButton, refreshButton] : [refreshButton]
    }
    // Failed - Clear backup - Retry
    // Cancelled - Clear backup - Retry
    if (jobFailed || jobCancelled) {
      if (user("canUpdateDevices", node) && user("canViewAndManageBackupData")) {
        return showRetryOption({ nodeId: node.id, isUp: node.isUp }) ? [clearButton, retryButton] : [clearButton]
      }
      return [closeButton]
    }
    // In progress - Cancel backup, Close and continue
    return canCancelLockhartJob(actionType) ? [cancelButton, continueButton] : [continueButton]
  }

  return (
    <Modal
      size="md"
      unmount={unmount}
      heading={modalTitle}
      withCloseX
      showCloseButton={false}
      buttons={getModalButtons()}
    >
      {loading ? (
        <Loading />
      ) : actionType && error !== "UNKNOWN_JOB_UID" ? (
        <Flex flexDirection="column" gap={tokens.spacing[4]} color="colorTextStrong">
          {detailsSections.map(({ title, icon, text }) => (
            <DetailsSection key={title} title={title} icon={icon} name={text} />
          ))}

          <StyledDivider />
          <Flex flexDirection="column" gap={tokens.spacing[2]}>
            <Flex justifyContent="space-between">
              <Text type="headingXs" fontWeight={tokens.typography.fontWeight.medium}>
                {dateTime(createTime)}
              </Text>
              <Flex gap={tokens.spacing[2]} alignItems="center">
                {jobFailed || jobCancelled || error ? (
                  <>
                    <CircleExclamationIcon color="colorAlertError" />
                    <Text type="body" color="colorAlertError">
                      {jobFailed ? localized("Job failed") : localized("Job interrupted")}
                    </Text>
                  </>
                ) : (
                  !isPreparing && (
                    <>
                      {jobCompletedWithWarnings ? (
                        <ExclamationTriangle color="colorAlertWarning" />
                      ) : (
                        jobCompleted && <CircleCheckIcon color="colorAlertSuccess" />
                      )}
                      <Text type="body">
                        {jobCompletedWithWarnings
                          ? localized("{{percentage}}% completed with warnings", {
                              percentage,
                            })
                          : localized("{{percentage}}% completed", { percentage })}
                      </Text>
                    </>
                  )
                )}
              </Flex>
            </Flex>
            <ProgressBar disabled={error || jobFailed || jobCancelled} progress={percentage} />
            {isPreparing ? (
              <Text type="bodyXs" color="colorTextWeakest">
                {preparingJobText}
              </Text>
            ) : (
              jobTotalFiles >= 1 && (
                <Flex gap={tokens.spacing[1]} color="colorTextWeakest">
                  <Text type="bodyXs">{processedDataText}</Text>
                  {"|"}
                  <Text type="bodyXs">{filesScannedText}</Text>
                </Flex>
              )
            )}
          </Flex>
          {error && (
            <AlertMessage variant="danger" titleToken={localizationKey("Connection error")}>
              {localized(
                "Unable to connect with the agent. Wait a few minutes while we re-establish the connection, then click refresh to try again.",
              )}
            </AlertMessage>
          )}
          {jobCompleted && jobTotalFiles === 0 && (
            <AlertMessage variant="success" titleToken={localizationKey("Job completed")}>
              {jobCompletedText}
            </AlertMessage>
          )}
          {jobCompletedWithWarnings && (
            <AlertMessage variant="warning" titleToken={localizationKey("Job completed with warnings")}>
              {jobCompletedWithWarningsText}
            </AlertMessage>
          )}
          {jobCancelled && (
            <AlertMessage variant="danger" titleToken={localizationKey("Job interrupted")}>
              {localized("The job cannot be completed because it was cancelled.")}
            </AlertMessage>
          )}
        </Flex>
      ) : (
        <AlertMessage variant="danger">
          {localized(
            "Unable to get job details. Wait a few minutes while we re-establish the connection, then click refresh to try again.",
          )}
        </AlertMessage>
      )}
    </Modal>
  )
}

export default memo(RunningJobModal)

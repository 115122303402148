import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"

const StyledListItem = styled.li`
> * {
  display: flex;
  justify-content: space-between;
  ${({ theme, withTopBorder }) => (withTopBorder ? `border-top: 1px solid ${theme.colorBorderWeakest};` : "")}
  ${({ theme, withBottomBorder }) => (withBottomBorder ? `border-bottom: 1px solid ${theme.colorBorderWeakest};` : "")}
  padding: ${tokens.spacing[2]};
  padding-right: ${tokens.spacing[7]};

  &.with-action {
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: ${({ theme }) => theme.colorForegroundHover};
      .label-container span {
        color: ${({ theme }) => theme.colorTextAction};
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:focus-visible {
      background-color: ${({ theme }) => theme.colorForegroundHover};
      outline-color: ${({ theme }) => theme.colorForegroundFocus};
    }
  }
}
`

const DataListItem = ({ icon, labelText, value = 0, action, href, withTopBorder = true, withBottomBorder }) => {
  const content = (
    <>
      <Flex alignItems="center" gap={tokens.spacing[2]}>
        {icon && (
          <Flex justifyContent="center" width={20}>
            {icon}
          </Flex>
        )}
        <div className="label-container">
          <Text type="body" color="colorTextWeak">
            {labelText}
          </Text>
        </div>
      </Flex>
      <Text type="body" color="colorTextWeak">
        {value}
      </Text>
    </>
  )
  return (
    <StyledListItem {...{ withTopBorder, withBottomBorder }}>
      {action ? (
        <a href={href} onClick={action} className="with-action">
          {content}
        </a>
      ) : (
        <div>{content}</div>
      )}
    </StyledListItem>
  )
}

export const dataListItemPropTypes = {
  labelText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.node,
}

DataListItem.propTypes = dataListItemPropTypes

export default DataListItem

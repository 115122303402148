import { evolve, mergeLeft, trim } from "ramda"
import { Checkbox, Input, Modal, Select } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { localizationKey, localized, validations } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { useForm } from "js/includes/common/hooks"
import { createWiFiNetworkPolicy } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

const formValidations = {
  configName: validations.required,
  wifiName: validations.required,
  password: (value, { security }) => (security === "NONE" ? { success: true } : validations.required(value)),
}

const trimNames = evolve({
  configName: trim,
  wifiName: trim,
})

export default function NetworkModal({ unmount, network, updatePolicyItem, securityOptions, parentPolicy }) {
  const { values, validation, onChange, validateForm } = useForm({
    fields: {
      configName: network?.configName || "",
      wifiName: network?.wifiName || "",
      security: network?.security || "NONE",
      password: network?.password || "",
      hidden: network?.hidden ?? false,
    },
    validate: formValidations,
  })

  const handleSaveNetwork = () => {
    if (!validateForm()) return
    const newNetwork = trimNames(values)
    createWiFiNetworkPolicy(mergeLeft(newNetwork, network), updatePolicyItem, parentPolicy)
    unmount()
  }

  return (
    <Modal
      titleGroup={{
        titleToken: localizationKey("Wi-Fi Network"),
      }}
      unmount={unmount}
      size="sm"
      buttons={[
        {
          type: "save",
          onClick: handleSaveNetwork,
          labelToken: network.guid ? localizationKey("Update") : localizationKey("Save"),
        },
      ]}
    >
      <Flex flexDirection="column" gap={spacing[3]} marginBottom={spacing[4]}>
        <Input
          labelText={localized("Configuration name")}
          value={values.configName}
          onChange={e => onChange("configName", e.target.value)}
          errorMessage={validation.message.configName}
          required
        />
        <Input
          labelText={localized("Wi-Fi name (SSID)")}
          tooltipText={localized("Please note that Wi-Fi names are case-sensitive")}
          value={values.wifiName}
          onChange={e => onChange("wifiName", e.target.value)}
          errorMessage={validation.message.wifiName}
          required
        />
        <Select
          labelId="networkSecurityLabel"
          labelToken={localizationKey("Security")}
          options={securityOptions}
          value={values.security}
          defaultValue={values.security}
          onChange={e => onChange("security", e)}
        />
        {values.security !== "NONE" && (
          <Input
            labelText={localized("Password")}
            errorMessage={validation.message.password}
            value={values.password}
            onChange={e => onChange("password", e.target.value)}
            isSecureInput
            required
          />
        )}
        <Checkbox
          checked={values.hidden}
          label={localized("Hidden network")}
          onChange={({ isChecked }) => onChange("hidden", isChecked)}
        />
      </Flex>
    </Modal>
  )
}

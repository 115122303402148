import { fetch, fetchJson } from "js/includes/common/utils"
import qs from "qs"

export const getOrganizations = () => fetchJson("/client/list")

export const getRootFolderItemsMove = () => fetchJson(`/knowledgebase/rootfolder`)

export const getFolderItemsMove = folderId => fetchJson(`/knowledgebase/folder/${folderId}`)

export const getRootFolderItems = clientId =>
  fetchJson(`/knowledgebase/rootfolder${clientId ? `/client/${clientId}` : ""}/items`)

export const getFolderItems = folderId => fetchJson(`/knowledgebase/folder/${folderId}/items`)

export const getKBContent = ({
  searchCriteria,
  clientId,
  folderId,
  extensions,
  includeArchived,
  types,
  sortBy,
  pageIndex,
  pageSize,
}) =>
  fetchJson("/knowledgebase/articles/search", {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria,
        clientId,
        folderId,
        ...(extensions && { extensions }),
        includeArchived,
        ...(types && { types }),
        ...(sortBy && { sortBy }),
        pageIndex,
        pageSize,
      }),
    },
  })

export const getKBContentTooltip = ({ searchCriteria, clientId, documentIds, folderIds }) =>
  fetchJson("/knowledgebase/articles/search/tooltip", {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria,
        clientId,
        documentIds,
        folderIds,
      }),
    },
  })

export const getGlobalFolderItems = folderId => fetchJson(`/knowledgebase/folder/${folderId}/global/items`)

export const getKnowledgeBaseDocument = documentId => fetchJson(`/knowledgebase/document/${documentId}`)

export const getKnowledgeBaseGlobalDocument = documentId => fetchJson(`/knowledgebase/document/global/${documentId}`)

export const getKnowledgeBaseSearchDocuments = ({ clientId, searchQuery, searchFolder = false }) =>
  fetchJson(
    `/knowledgebase/document${clientId ? `/client/${clientId}` : ""}/search${searchFolder ? "?searchFolder=true" : ""}`,
    {
      options: {
        method: "POST",
        body: searchQuery,
      },
    },
  )

export const deleteKnowledgeBaseItems = ({ body, clientId }) =>
  fetchJson(`/knowledgebase${clientId ? `/client/${clientId}` : ""}/delete/items`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const archiveKnowledgeBaseItems = ({ body, clientId }) =>
  fetchJson(`/knowledgebase${clientId ? `/client/${clientId}` : ""}/archive/items`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const restoreKnowledgeBaseItems = ({ body, clientId }) =>
  fetchJson(`/knowledgebase${clientId ? `/client/${clientId}` : ""}/restore/items`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const createKnowledgeBaseFolder = ({ clientId, parentId, body }) =>
  fetchJson(`/knowledgebase/folder${parentId ? `/parent/${parentId}` : ""}${clientId ? `/client/${clientId}` : ""}`, {
    options: {
      method: "POST",
      body,
    },
  })

export const updateKnowledgeBaseFolder = ({ clientId, folderId, body }) =>
  fetchJson(`/knowledgebase/folder/${folderId}${clientId ? `/client/${clientId}` : ""}`, {
    options: {
      method: "PUT",
      body,
    },
  })

export const uploadDocuments = ({ clientId, folderId, uploadedContentIds }) => {
  return fetchJson(
    `/knowledgebase/documents${folderId ? `/folder/${folderId}` : ""}${clientId ? `/client/${clientId}` : ""}/upload`,
    {
      options: {
        method: "POST",
        body: JSON.stringify(uploadedContentIds),
      },
    },
  )
}

export const knowledgeBaseMoveAction = async ({ body }) =>
  await fetch("/knowledgebase/move", {
    options: {
      method: "PATCH",
      body,
    },
  })

export const createKnowledgeBaseDocument = ({ clientId, folderId, body }) => {
  return fetchJson(
    `/knowledgebase/document${folderId ? `/folder/${folderId}` : ""}${clientId ? `/client/${clientId}` : ""}`,
    {
      options: {
        method: "POST",
        body: JSON.stringify(body),
      },
    },
  )
}

export const updateKnowledgeBaseDocument = ({ clientId, documentId, body }) => {
  return fetchJson(`/knowledgebase/document/${documentId}${clientId ? `/client/${clientId}` : ""}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(body),
    },
  })
}

export const getKnowledgeBasePublicUrl = documentId => fetch(`/knowledgebase/document/${documentId}/public/url`)

export const getKnowledgeBaseGlobalPublicUrl = documentId =>
  fetch(`/knowledgebase/document/${documentId}/global/public/url`)

export const getKnowledgeBaseGlobalPublicUrlInlineImage = (documentId, attachmentId) =>
  fetch(`/knowledgebase/document/${documentId}/${attachmentId}/global/public/url`)

export const getKnowledgeBasePublicUrlInlineImage = (documentId, attachmentId) =>
  fetch(`/knowledgebase/document/${documentId}/${attachmentId}/public/url`)

export const getKnowledgeBasePathItems = ({ itemId: id, isFile }) => {
  return fetchJson(`/knowledgebase/path/${id}?file=${isFile}`)
}

export const importKnowledgeBaseTemplate = ({ documentTemplateId, clientId = null }) => {
  return fetchJson(`/attributes/client/document/${documentTemplateId}/csvBulkImport`, {
    options: {
      method: "POST",
    },
  })
}

export const getKBNinjaDocument = ({ documentId }) =>
  fetchJson(`/knowledgebase/document/${documentId}/global/public/url/share/NJDocument`)

export const getKBDocument = ({ documentId }) =>
  fetchJson(`/knowledgebase/document/${documentId}/global/public/url/share`)

export const getAccessHistory = ({ params }) =>
  fetchJson(
    `/knowledgebase/document/global/public/url/share/GlobalHistory?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
    {
      options: {
        method: "POST",
      },
    },
  )

export const getPublicLinkAccessHistory = ({ linkId, params }) =>
  fetchJson(
    `/knowledgebase/document/${linkId}/global/public/url/historyAccess?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
    {
      options: {
        method: "POST",
      },
    },
  )

export const createKBNinjaDocument = ({ documentId, body }) =>
  fetch(`/knowledgebase/document/${documentId}/global/public/url/share/NJDocument`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const updateKBNinjaDocument = ({ documentId, token, body }) =>
  fetch(`/knowledgebase/document/${documentId}/NJDocument/${token}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(body),
    },
  })

export const createKBDocument = ({ documentId, body }) =>
  fetch(`/knowledgebase/document/${documentId}/global/public/url/share`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const updateKBDocument = ({ documentId, token, body }) =>
  fetch(`/knowledgebase/document/${documentId}/KbDocument/${token}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(body),
    },
  })

export const deletePublicLinks = ({ body }) =>
  fetch(`/knowledgebase/document/global/public/url/share/delete`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const downloadAccessHistory = ({ params }) =>
  fetch(
    `/knowledgebase/document/global/public/url/csv?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
    { responseType: "arraybuffer", options: { method: "GET" } },
  )

export const downloadSharedLinks = ({ params }) =>
  fetch(
    `/knowledgebase/document/global/public/url/publicLinks/csv?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
    {
      responseType: "arraybuffer",
      options: { method: "GET" },
    },
  )

export const getKBDocuments = ({ params }) =>
  fetchJson(
    `/knowledgebase/documents?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
  )

export const getSharedLinks = ({ params }) =>
  fetchJson(
    `/knowledgebase/document/global/public/url/share/publicLinks?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
    {
      options: {
        method: "GET",
      },
    },
  )

export const editMSDocument = fileId =>
  window.open(`/ws/knowledgebase/wopi/msiframehtml/fileId/${fileId}/edit`, "_blank")

export const getKBDocumentsByIdOrName = ({ params }) =>
  fetchJson(
    `/knowledgebase/documents?${qs.stringify(params, {
      arrayFormat: "repeat",
      encode: false,
    })}`,
  )

export const getDocumentRevisions = documentId =>
  fetchJson(`/knowledgebase/revision/revisions/documentId/${documentId}`)

export const getRevision = revisionId => fetchJson(`/knowledgebase/revision/${revisionId}`)

export const restoreRevision = (documentId, revisionId) =>
  fetchJson(`/knowledgebase/revision/${revisionId}/document/${documentId}/restore`, {
    options: {
      method: "POST",
    },
  })

export const deleteRevisions = revisionIds =>
  fetchJson("/knowledgebase/revision/delete", {
    options: {
      method: "POST",
      body: JSON.stringify(revisionIds),
    },
  })

export const getFolderAccessRestriction = ({ folderId }) => {
  return fetchJson(`/knowledgebase/access/folder/${folderId}`)
}

export const getSharedFolderSelectedRoles = ({ folderId }) =>
  fetchJson(`/knowledgebase/enduser/folder/share/${folderId}`)

export const getRoles = ({ roleType }) => fetchJson(`/user-roles?roleType=${roleType}`)

export const getTechnicianPermissionRoles = async () => {
  const data = await fetchJson(`/appuser?userType=TECHNICIAN`)
  return data[0].roles
}

export const setShareFolderToEndUsers = body =>
  fetchJson(`/knowledgebase/enduser/folder/share`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })

export const revokeFolderEndUserAccess = folderIds =>
  fetchJson(`/knowledgebase/enduser/folder/share/revoke`, {
    options: {
      method: "POST",
      body: JSON.stringify(folderIds),
    },
  })

export const restrictFolderTechnicianAccess = payload =>
  fetchJson(`/knowledgebase/access/folder/restrict`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })

export const getEndUserSharedFolders = () => fetchJson(`/knowledgebase/enduser/folder/share/list`)

import { Root } from "@radix-ui/react-toggle"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

import tokens from "@ninjaone/tokens"

export const VARIANTS = {
  DEFAULT: "default",
  ACCESSORY: "accessory",
  UTILITY: "utility",
}

export function getVariantStyles({ variant, theme }) {
  switch (variant) {
    case VARIANTS.ACCESSORY:
      return `
        color: ${theme.colorTextStrong};
        border-color: ${theme.colorBorderWeak};
        background-color: ${theme.colorBackground};
      `
    case VARIANTS.UTILITY:
      return `
        color: ${theme.colorTextStrong};
        background-color: transparent;
        border-color: transparent;
      `
    case VARIANTS.DEFAULT:
    default:
      return `
        color: ${theme.colorTextAction};
        border-color: ${theme.colorBorderWeak};
        background-color: ${theme.colorBackground};
      `
  }
}

export function getStyles({ theme, compact, fullWidth }) {
  return `
    display: flex;
    align-items: center;
    justify-content: center;

    gap: ${tokens.spacing[2]};

    box-sizing: border-box;

    border-style: solid;
    border-width: 1px;
    border-radius: ${tokens.borderRadius[1]};
    
    padding: ${tokens.spacing[1]} ${tokens.spacing[3]};

    height: ${compact ? "32px" : "38px"};
    width: ${fullWidth ? "100%" : "fit-content"};

    font-family: ${tokens.typography.fontFamily.primary};
    font-size: ${tokens.typography.fontSize.body};
    font-weight: ${tokens.typography.fontWeight.medium};
    text-wrap: nowrap;

    cursor: pointer;

    &:focus-visible {
      outline: 2px solid ${theme.colorForegroundFocus};
      outline-offset: -2px;
    }

    &:hover {
      color: ${theme.colorTextActionStrong};
      background-color: ${theme.colorForegroundHover};
    }

    &[data-state="on"] {
      color: ${theme.colorTextActionStrong};
      background-color: ${theme.colorForegroundSelected};
      border-color: ${theme.colorBorderDecorativeStrong};

      &:hover {
        background-color: ${theme.colorForegroundHover};
      }
    }

    &[data-disabled] {
      color: ${theme.colorTextDisabled};
      background-color: ${theme.colorBackgroundCtaDisabled};
      border-color: ${theme.colorBackgroundCtaDisabled};

      &:hover {
        cursor: not-allowed;
        color: ${theme.colorTextDisabled};
        background-color: ${theme.colorBackgroundCtaDisabled};
        border-color: ${theme.colorBackgroundCtaDisabled};
      }
    }
  `
}

export const shouldForwardProp = prop => !["compact", "fullWidth"].includes(prop)

const StyledRoot = styled(Root, { shouldForwardProp })`
  ${getStyles}
  ${getVariantStyles}
`

export default function ToggleButton({
  variant = VARIANTS.DEFAULT,
  labelText,
  disabled,
  pressed,
  onPressedChange,
  compact = false,
  fullWidth = false,
  leftRenderer,
  rightRenderer,
  "data-testid": dataTestid,
}) {
  return (
    <StyledRoot data-testid={dataTestid} {...{ compact, fullWidth, variant, disabled, pressed, onPressedChange }}>
      {leftRenderer && typeof leftRenderer === "function" ? leftRenderer() : null}
      {labelText}
      {rightRenderer && typeof rightRenderer === "function" ? rightRenderer() : null}
    </StyledRoot>
  )
}

ToggleButton.propTypes = {
  /**
   * Variants for the button.
   */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /**
   * Text for the button.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * State of the button.
   */
  pressed: PropTypes.bool,
  /**
   * A standardized smaller height for the component.
   */
  compact: PropTypes.bool,
  /**
   * Expand the button to the full width of the parent.
   */
  fullWidth: PropTypes.bool,
  /**
   * The disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * Function to render on the `left` side of the `labelText`.
   */
  leftRenderer: PropTypes.func,
  /**
   * Function to render on the `right` side of the `labelText`.
   */
  rightRenderer: PropTypes.func,
}

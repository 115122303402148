// Modified from: https://stackoverflow.com/questions/6730009/validate-a-file-name-on-windows
import { faFile } from "@fortawesome/pro-light-svg-icons"
import {
  faFileAlt,
  faFileArchive,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from "@fortawesome/pro-solid-svg-icons"
import { contains, joinByComma, toBytes } from "js/includes/common/utils/ssrAndWebUtils"
import { faFileVisio } from "media/icons"
import { always, compose, concat, cond, equals, flatten, map, T } from "ramda"
import { upload } from "./fetch"
import { noop } from "@ninjaone/utils"

export const windowsFileFolderNameValidation = /^(?!(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)[^<>:"/\\|?*]*[^<>:"/\\|?*.]$/i
export const macFileFolderNameValidation = /^(?!(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)[^:]*[^:]$/i

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    //Stack Overflow link https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const readFileAsBinaryString = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const fileAsBinaryString = reader.result
      resolve(fileAsBinaryString)
    }

    reader.onabort = () => {
      throw new Error("file reading was aborted")
    }

    reader.onerror = () => {
      throw new Error("file reading has failed")
    }

    reader.readAsBinaryString(file)
  })

export const getFileExtension = fileName => fileName.split(".").pop()

export function downloadFile(url, fileName, newWindow = false) {
  const element = document.createElement("a")
  element.setAttribute("style", "display: none;")
  element.setAttribute("href", url)
  element.setAttribute("download", fileName)
  newWindow && element.setAttribute("target", "_blank")
  document.body.appendChild(element)
  element.click()
  element.remove()
}

export function isFileValid(file, validFileTypes) {
  const fileNameExtension = getFileExtension(file.name)
  return validFileTypes.split(",").some(fy => `.${fileNameExtension.toLowerCase()}` === fy)
}

export function getFileNameFromContentDispositionHeader(headers) {
  const rawName = headers?.get("content-disposition")
  if (!rawName) return null
  const match = rawName.replaceAll("+", " ").match(/(filename=\s*)([^;]*)/)
  if (!match || match?.length < 3 || match[2].length === 0) return null
  return `${decodeURIComponent(match[2].replace(/['"]/g, ""))}`
}

export const XML = "xml"
export const HTML = "html"
export const PDF = "pdf"
export const LOG = "log"
export const CSV = "csv"
export const TXT = "txt"
export const ZIP = "zip"
export const RAR = "rar"
export const TAR = "tar"
export const MS_CABINET_ARCHIVE = "cab"
export const MP3 = "mp3"
export const EML = "eml"

// NINJA ALLOWED UPLOAD EXTENSIONS
export const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif"]

export const WORD_EXTENSIONS = ["dot", "wbk", "doc", "docx", "rtf"]

export const EXCEL_EXTENSIONS = ["xls", "xlsx", "ods"]

export const POWER_POINT_EXTENSIONS = ["ppt", "pptx", "pps", "ppsx", "sldx"]

export const VISIO_EXTENSIONS = ["vsd", "vsdx"]

export const WINDOWS_APPLICATIONS_INSTALLER_EXTENSIONS = ["exe", "msi"]

export const MAC_APPLICATIONS_INSTALLER_EXTENSIONS = ["dmg", "pkg"]

export const INSTALLER_EXTENSIONS = [
  ...WINDOWS_APPLICATIONS_INSTALLER_EXTENSIONS,
  ...MAC_APPLICATIONS_INSTALLER_EXTENSIONS,
]

const WOPITEST_EXTENSIONS = ["wopitest", "wopitestx"] // Used for testing the MS integration

export const MS_DOCUMENT_EXTENSIONS = [
  ...WORD_EXTENSIONS,
  ...EXCEL_EXTENSIONS,
  ...POWER_POINT_EXTENSIONS,
  ...WOPITEST_EXTENSIONS,
]

export const DOCUMENT_EXTENSIONS = [...MS_DOCUMENT_EXTENSIONS, PDF]

// MS EDITING TOOL SUPPORTED EXTENSIONS
const WORD_EDIT_EXTENSIONS = ["docm", "docx", "odt"]

const EXCEL_EDIT_EXTENSIONS = ["xlsx", "ods", "xlsb", "xlsm"]

const POWER_POINT_EDIT_EXTENSIONS = ["ppsx", "pptx", "odp"]

export const MS_DOCUMENT_EDIT_EXTENSIONS = [
  ...WORD_EDIT_EXTENSIONS,
  ...EXCEL_EDIT_EXTENSIONS,
  ...POWER_POINT_EDIT_EXTENSIONS,
  ...WOPITEST_EXTENSIONS,
]

// MS VIEWING TOOL SUPPORTED EXTENSIONS
const WORD_VIEW_EXTENSIONS = ["doc", "docx", "dot"]

const EXCEL_VIEW_EXTENSIONS = ["xls", "xlsx", "ods"]

const POWER_POINT_VIEW_EXTENSIONS = ["ppsx", "ppt", "pptx", "pps"]

export const MS_DOCUMENT_VIEW_EXTENSIONS = [
  ...WORD_VIEW_EXTENSIONS,
  ...EXCEL_VIEW_EXTENSIONS,
  ...POWER_POINT_VIEW_EXTENSIONS,
]

/* ------------------------------------------------------------------------- */

export const WEB_EXTENSIONS = [XML, HTML]

export const COMPRESSED_FILE_EXTENSIONS = [ZIP, RAR, TAR]

export const MISCELLANEOUS_EXTENSIONS = [MS_CABINET_ARCHIVE, TXT, LOG, PDF, CSV, MP3, EML]

const generateFileExtensions = compose(map(concat(".")), flatten)

export const ATTACHMENT_FILE_EXTENSIONS = generateFileExtensions([
  IMAGE_EXTENSIONS,
  MISCELLANEOUS_EXTENSIONS,
  WORD_EXTENSIONS,
  EXCEL_EXTENSIONS,
  POWER_POINT_EXTENSIONS,
  VISIO_EXTENSIONS,
  WEB_EXTENSIONS,
  COMPRESSED_FILE_EXTENSIONS,
])

export const INLINE_FILE_EXTENSIONS = generateFileExtensions([IMAGE_EXTENSIONS])

export const IMAGE_FILE_MIME_TYPES = ["image/jpeg", "image/png", "image/gif"]

// TODO: Find WOPITEST_EXTENSIONS
export const ATTACHMENT_FILE_MIME_TYPES = [
  ...IMAGE_FILE_MIME_TYPES,
  "application/rtf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-visio.drawing",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.visio",
  "application/vnd.ms-cab-compressed",
  "application/zip-compressed",
  "application/x-iwork-numbers-sffnumbers",
  "application/x-rar-compressed",
  "application/vnd.rar",
  "application/x-tar",
  "application/x-zip",
  "application/x-rar",
  "application/zip",
  "application/pdf",
  "text/plain",
  "text/rtf",
  "text/csv",
  "text/x-c",
  "text/xml",
  "text/html",
  "audio/mpeg",
  "message/rfc822",
  "video/quicktime",
  "video/mp4",
]

export const acceptedImageFileMimeTypes = joinByComma(IMAGE_FILE_MIME_TYPES)
export const acceptedAttachmentFileMimeTypes = joinByComma(ATTACHMENT_FILE_MIME_TYPES)

export const twentyMiB = toBytes(20, "MiB")

export const IMAGE_TYPES = ["image/png", "image/jpg", "image/jpeg"]

export const acceptedAttachmentFiles = joinByComma(ATTACHMENT_FILE_EXTENSIONS)
export const acceptedInlineFiles = joinByComma(INLINE_FILE_EXTENSIONS)

export const getIconProps = (extension, fontSize = "40px") =>
  cond([
    [equals(PDF), always({ icon: faFilePdf, fontSize, color: "ninjaRed" })],
    [equals(CSV), always({ icon: faFileCsv, fontSize, color: "ninjaGreen" })],
    [contains(IMAGE_EXTENSIONS), always({ icon: faFileImage, fontSize, color: "ninjaBlueSaturated" })],
    [contains(WORD_EXTENSIONS), always({ icon: faFileWord, fontSize, color: "ninjaBlueSaturated" })],
    [contains(POWER_POINT_EXTENSIONS), always({ icon: faFilePowerpoint, fontSize, color: "lightOrange" })],
    [contains(EXCEL_EXTENSIONS), always({ icon: faFileExcel, fontSize, color: "ninjaGreen" })],
    [contains(VISIO_EXTENSIONS), always({ icon: faFileVisio, fontSize, color: "ninjaBlueSaturated" })],
    [contains(WEB_EXTENSIONS), always({ icon: faFileCode, fontSize, color: "ninjaBlueSaturated" })],
    [contains(COMPRESSED_FILE_EXTENSIONS), always({ icon: faFileArchive, fontSize, color: "ninjaBlue" })],
    [contains(MISCELLANEOUS_EXTENSIONS), always({ icon: faFileAlt, fontSize, color: "ninjaLight" })],
    [contains(INSTALLER_EXTENSIONS), always({ icon: faFile, fontSize, color: "ninjaLight" })],
    [T, always({ icon: faFile, fontSize, color: "ninjaBlueSaturated" })],
  ])(extension)

export const uploadFile = ({ file, url, onProgress = noop }) => {
  const body = { file }

  return new Promise(async function(resolve, reject) {
    try {
      const { xhr } = await upload(url, {
        body,
        onProgressHandler: _progress => {
          const progress = (_progress.loaded * 100) / _progress.total
          onProgress(progress)
        },
      })

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const uploadedFile = JSON.parse(xhr.response)
          resolve(uploadedFile)
        }
      }

      xhr.onerror = () => {
        reject(new Error("Failed to upload file"))
      }
    } catch (e) {
      reject(e)
    }
  })
}

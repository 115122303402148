import { fetchJson, ninjaReportError, showSuccessMessage, showErrorMessage, localized } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { vmHostDataspecs } from "js/state/actions/virtualization/dataspecs"
import {
  fetchDeviceStatus,
  actionCall,
  fetchNodeDash,
  clearDevicePolicyOverrides,
  updateDevicePolicy,
  fetchNodeData,
} from "js/state/actions/virtualization/common"

export const fetchDashboardData = deviceId => async dispatch => {
  fetchNodeDash(deviceId, vmHostDataspecs, dispatch, "INIT_NODE_DATA", "INIT_DASHBOARD_DATASETS")
}

export const fetchHostDeviceStatus = deviceId => async dispatch => {
  fetchDeviceStatus(deviceId, dispatch, "SET_NODE_STATUS")
}

export const restartVMHost = (deviceId, actionId) => async dispatch => {
  try {
    await actionCall(deviceId, actionId)
    fetchDeviceStatus(deviceId, dispatch, "SET_NODE_STATUS")
  } catch (error) {
    ninjaReportError(error)
  }
}

export const updateDeviceName = (name, deviceId, node) => async dispatch => {
  showSuccessMessage(localized("Saving..."))
  try {
    const response = await fetchJson(`/node/${deviceId}`, {
      options: {
        method: "PUT",
        body: JSON.stringify({
          ...node,
          friendlyName: name,
        }),
      },
      useMfaUrl: true,
    })
    if (response.resultCode === "SUCCESS") {
      dispatch({
        type: "UPDATE_NODE_FRIENDLY_NAME",
        name,
      })
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localized("Error saving/updating"))
    ninjaReportError(error)
  }
}

export const updateHostOwner = owner => dispatch =>
  dispatch({
    type: "UPDATE_HOST_NODE_OWNER",
    owner,
  })

export const updateOrganizationAndLocation = (clientId, locationId, deviceId, node) => async dispatch => {
  showSuccessMessage(localized("Saving..."))
  try {
    const response = await fetchJson(`/webapp/migrateagent`, {
      options: {
        method: "POST",
        body: JSON.stringify({
          clientId,
          locationId,
          nodeIdList: [deviceId],
        }),
      },
    })
    if (response.resultCode === "SUCCESS") {
      fetchNodeData(deviceId, dispatch, "INIT_NODE_DATA")
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localized("Error saving/updating"))
    ninjaReportError(error)
  }
}

export const clearPolicyOverrides = (deviceId, node) => async dispatch => {
  clearDevicePolicyOverrides(deviceId, node, dispatch, "INIT_NODE_DATA")
}

export const updatePolicy = (deviceId, node, policyId, revertToOrganizationPolicy) => async dispatch => {
  updateDevicePolicy(deviceId, node, policyId, revertToOrganizationPolicy, dispatch, "INIT_NODE_DATA")
}

export const clearSensorHealthFitler = filter => async dispatch => {
  dispatch({
    type: filter === "sensor-type" ? "CLEAR_HEALTH_SENSOR_TYPE" : "CLEAR_HEALTH_SENSOR_STATUS",
  })
}

export const setHealhSensorTypeFilter = filterData => async dispatch => {
  dispatch({
    type: "SET_HEALTH_SENSOR_TYPE_FILTER",
    filterData,
  })
}

export const setHealhSensorStatusFilter = filterData => async dispatch => {
  dispatch({
    type: "SET_HEALTH_SENSOR_STATUS_FILTER",
    filterData,
  })
}

export const setWarrantyDates = warrantyDates => {
  return {
    type: "UPDATE_WARRANTY_DATES",
    payload: warrantyDates,
  }
}

import { flatten } from "ramda"
import { filterTypes } from "@ninjaone/components/src/DataTable"
import { noop } from "@ninjaone/utils"
import {
  bytesPrefixMapper,
  capitalizeFirstLetter,
  isFeatureEnabled,
  localizationKey,
  localized,
  validations,
} from "js/includes/common/utils"
import { destinationOptions } from "js/includes/editors/Policy/Sections/Backups/common/destination"

export const getOrganizationsFilter = (onFilterChange = noop, historyMode = false) => ({
  name: "organizations",
  type: filterTypes.SEARCHABLE_SELECT,
  labelToken: localizationKey("Organization"),
  onFilterChange,
  componentProps: {
    isRemovable: false,
    endpoint: "/lockhart/filter/clients",
    searchParams: ({ query: name }) => ({ historyMode, ...(name && { name }) }),
    dataFormatter: ({ id, name, deleted }) => ({
      label: `${name}${deleted ? ` (${localized("deleted")})` : ""}`,
      value: id,
    }),
  },
})

export const getDevicesFilter = (client, historyMode = false) => ({
  name: "devices",
  type: filterTypes.SEARCHABLE_SELECT,
  labelToken: localizationKey("Device"),
  componentProps: {
    endpoint: "/lockhart/filter/devices",
    searchParams: ({ query: name }) => ({
      ...(client?.id && { clientId: [client.id] }),
      historyMode,
      ...(name && { name }),
    }),
    dataFormatter: ({ id, name, client: { id: organizationId }, deleted }) => ({
      label: `${name}${deleted ? ` (${localized("deleted")})` : ""}`,
      value: id,
      organizationId,
    }),
  },
})

export const getDataTypesFilter = ({ name = "types", labelToken = localizationKey("Type") } = {}) => ({
  name,
  type: filterTypes.MULTISELECT,
  labelToken,
  componentProps: {
    isRemovable: false,
    options: [
      ...(isFeatureEnabled("backup_arrow_image")
        ? [
            {
              label: localized("Image"),
              value: "ARROW_IMAGE",
            },
          ]
        : []),
      {
        label: localized("File/Folder"),
        value: "FILE_FOLDER",
      },
      {
        label: isFeatureEnabled("backup_arrow_image") ? localized("Legacy") : localized("Image"),
        value: "IMAGE",
      },
    ],
  },
})

export const getStorageSiteFilter = () => ({
  name: "storageSite",
  type: filterTypes.MULTISELECT,
  labelToken: localizationKey("Storage site"),
  componentProps: {
    isRemovable: false,
    options: [
      { value: "CLOUD", label: localized("Cloud") },
      { value: "LOCAL", label: localized("Local") },
    ],
  },
})

export const getDestinationsFilter = () => ({
  name: "destinations",
  type: filterTypes.MULTISELECT,
  labelToken: localizationKey("Destination"),
  componentProps: {
    isRemovable: false,
    options: destinationOptions(),
  },
})

export const getLocationsFilter = ({ client, hidden, isRemovable, historyMode = false } = {}) => ({
  name: "locations",
  type: filterTypes.SEARCHABLE_SELECT,
  labelToken: localizationKey("Location"),
  hidden: hidden ?? !client?.id,
  componentProps: {
    isRemovable,
    endpoint: "/lockhart/filter/locations",
    dataFormatter: ({ id, name, client: { id: organizationId, name: organizationName }, deleted }) => ({
      label: `${name}${deleted ? ` (${localized("deleted")})` : ""} - ${organizationName}`,
      value: id,
      organizationId,
    }),
    searchParams: ({ query: name }) => ({
      ...(client?.id && { clientId: [client.id] }),
      historyMode,
      ...(name && { name }),
    }),
  },
})

const periodMapper = {
  DAYS: localizationKey("day(s)"),
  WEEKS: localizationKey("week(s)"),
  MONTHS: localizationKey("month(s)"),
  YEARS: localizationKey("year(s)"),
}

const periodOptions = [
  { labelToken: localizationKey("Days"), value: "DAYS" },
  { labelToken: localizationKey("Weeks"), value: "WEEKS" },
  { labelToken: localizationKey("Months"), value: "MONTHS" },
  { labelToken: localizationKey("Years"), value: "YEARS" },
]

export const getLastFailureFilter = () => ({
  name: "lastFailure",
  type: filterTypes.INPUT_WITH_UNIT_DROPDOWN,
  labelToken: localizationKey("Last failure"),
  componentProps: {
    titleToken: localizationKey("Last failure within"),
    inputValidators: [validations.required],
    dropdownLabelToken: localizationKey("Period"),
    selectedFormatter: ({ inputValue, selectedValue }) =>
      localized("Within {{value}} {{unit}}", {
        value: inputValue,
        unit: localized(periodMapper[selectedValue]),
      }),
    inputProps: {
      min: 0,
      placeholder: 0,
    },
    options: periodOptions,
  },
})

export const getLastSuccessFilter = () => ({
  name: "lastSuccess",
  type: filterTypes.INPUT_WITH_UNIT_DROPDOWN,
  labelToken: localizationKey("Last success"),
  componentProps: {
    titleToken: localizationKey("Last success more than"),
    inputValidators: [validations.required],
    dropdownLabelToken: localizationKey("Period"),
    selectedFormatter: ({ inputValue, selectedValue }) => `> ${inputValue} ${localized(periodMapper[selectedValue])}`,
    inputProps: {
      min: 0,
      placeholder: 0,
    },
    options: periodOptions,
  },
})

export const getLastSeenFilter = () => ({
  name: "contactTime",
  type: filterTypes.INPUT_WITH_UNIT_DROPDOWN,
  labelToken: localizationKey("Last seen"),
  componentProps: {
    titleToken: localizationKey("Last seen more than"),
    inputValidators: [validations.required],
    dropdownLabelToken: localizationKey("Period"),
    selectedFormatter: ({ inputValue, selectedValue }) => `> ${inputValue} ${localized(periodMapper[selectedValue])}`,
    inputProps: {
      min: 0,
      placeholder: 0,
    },
    options: periodOptions,
  },
})

const storageOptions = [
  { labelToken: localizationKey("KB"), value: "KiB" },
  { labelToken: localizationKey("MB"), value: "MiB" },
  { labelToken: localizationKey("GB"), value: "GiB" },
  { labelToken: localizationKey("TB"), value: "TiB" },
]

export const getLocalSizeFilter = () => ({
  name: "localSize",
  type: filterTypes.INPUT_WITH_UNIT_DROPDOWN,
  labelToken: localizationKey("Local size"),
  componentProps: {
    titleToken: localizationKey("Greater than or equal to"),
    inputValidators: [validations.required, validations.isValidIntegerWithinRange(0, 1024)],
    dropdownLabelToken: localizationKey("Select size"),
    selectedFormatter: ({ inputValue, selectedValue }) => `>= ${inputValue} ${bytesPrefixMapper[selectedValue]}`,
    inputProps: {
      min: 0,
      placeholder: 0,
    },
    options: storageOptions,
  },
})

export const getCloudSizeFilter = () => ({
  name: "cloudSize",
  type: filterTypes.INPUT_WITH_UNIT_DROPDOWN,
  labelToken: localizationKey("Cloud size"),
  componentProps: {
    titleToken: localizationKey("Greater than or equal to"),
    inputValidators: [validations.required, validations.isValidIntegerWithinRange(0, 1024)],
    dropdownLabelToken: localizationKey("Select size"),
    selectedFormatter: ({ inputValue, selectedValue }) => `>= ${inputValue} ${bytesPrefixMapper[selectedValue]}`,
    inputProps: {
      min: 0,
      placeholder: 0,
    },
    options: storageOptions,
  },
})

export const getDeviceExistsFilter = () => ({
  name: "deviceExists",
  type: filterTypes.SINGLE_SELECT,
  labelToken: localizationKey("Device exists"),
  componentProps: {
    options: [
      { label: capitalizeFirstLetter(localized("true")), value: "true" },
      { label: capitalizeFirstLetter(localized("false")), value: "false" },
    ],
  },
})

export const getDeviceTypesFilter = () => ({
  name: "nodeClasses",
  type: filterTypes.MULTISELECT,
  labelToken: localizationKey("Device type"),
  componentProps: {
    options: [
      { label: localized("Mac Workstations"), value: "MAC" },
      { label: localized("Mac Server"), value: "MAC_SERVER" },
      { label: localized("Windows Workstations"), value: "WINDOWS_WORKSTATION" },
      { label: localized("Windows Server"), value: "WINDOWS_SERVER" },
    ],
  },
})

export const statuses = {
  ENABLED: ["ENABLED"],
  DISABLED: ["DISABLED", "DISABLED_PENDING_DELETION"],
  DELETED: ["ARCHIVED", "DELETED_PENDING_DELETION"],
  REJECTED: ["REJECTED", "REJECTED_PENDING_DELETION"],
}

export const doesNodeExistInNinja = status => flatten([statuses.ENABLED, statuses.DISABLED]).includes(status)

export const isNodeRejected = status => statuses.REJECTED.includes(status)

export const dateValues = {
  ALL_TIME: "ALL_TIME",
  TODAY: "TODAY",
  LAST_7_DAYS: "LAST_7_DAYS",
  LAST_30_DAYS: "LAST_30_DAYS",
  LAST_90_DAYS: "LAST_90_DAYS",
}

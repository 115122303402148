import styled from "@emotion/styled/macro"
import { sizer } from "@ninjaone/utils"

export const StyledEditorSidebarNavigation = styled.aside`
  overflow-y: auto;
`

export const StyledEditorSidebarNavigationDropdown = styled.div``

export const StyledEditorSidebarNavigationDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${sizer(9)};
`

export const StyledEditorSidebarNavigationItem = styled.div`
  user-select: none;
  display: grid;
  padding: ${sizer(2, 6, 2, 4)};
  grid-template-columns: ${({ hasIcon }) => (hasIcon ? sizer(5, "minmax(0, 1fr)", 4) : sizer("minmax(0, 1fr)", 4))};
  grid-template-rows: ${sizer(8)};
  grid-column-gap: ${sizer(4)};
  align-items: center;
  border-radius: ${sizer(1)};
  cursor: pointer;
  color: ${({ theme, danger, isActive }) => {
    return danger ? theme.color.red : isActive ? theme.colorTextActionStrong : theme.color.text
  }};
`

export const StyledEditorContent = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  overflow-y: scroll;
`

export const StyledEditor = styled.div`
  display: flex;
  position: absolute;
  z-index: 2050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme, backgroundColor = "" }) => backgroundColor || theme.color.background};
  overflow: hidden;
  color: ${({ theme }) => theme.color.text};
  min-width: 1024px;
  height: 100vh;
   
  header {
    ::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: ${sizer(13)};
      background-color: ${({ theme }) => theme.colorThemeBackground || theme.color.ninjaBlue};
    }

    grid-column: 1 / -1;
    grid-row: 1;

    display: flex;

    > * {
      position: relative;
      z-index: 1;
    }

    img {
      max-height: ${sizer(13)};
      padding: ${sizer(4, 0, 2)};
    }
  }

  ${StyledEditorSidebarNavigation} {
    grid-column: 1 / 4;
    grid-row: 2;

    * {
      &.active {
        ${StyledEditorSidebarNavigationItem} {
          background-color: ${({ theme }) => theme.color.linkBackground};
          color: ${({ theme }) => theme.color.action};
          svg {
            color: ${({ theme }) => theme.color.action};
          }
        }
      }
    }
  }

  main {
    grid-column: 4 / -1;
    grid-row: 2;
  }

  ${StyledEditorSidebarNavigation} + ${StyledEditorContent} {
    grid-column: 4 / 13;
    grid-row: 2;
  }
`

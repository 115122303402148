import { useSelector } from "react-redux"
import { entityType } from "js/includes/editors/User/Permissions/NinjaPSA/entities"
import { isGranted } from "js/includes/common/services/userPermissions"
import { isFeatureEnabled } from "./app"
import { isNumber, localized } from "./ssrAndWebUtils"

export const isNinjaPSAEnabledFromSettings = () =>
  isFeatureEnabled("ninja_psa") && window.store.getState()?.ninjaPSA?.configurations?.enabled

export const isUserAllowedToUseNinjaPSAAdministrativeActions = () => {
  return isFeatureEnabled("ninja_psa") && isGranted({ entityType, permissionName: "Configuration.NinjaPSA.Access" })
}

export const formatNumber = ({ value, withCurrencyStyle = false, currency = "USD" }) => {
  return isNumber(value)
    ? localized(value, {
        minRound: 2,
        maxRound: 2,
        ...(withCurrencyStyle && { styleType: "currency", currency }),
      })
    : "-"
}

export const ninjaPSAWampOperations = {
  invoices: {
    INVOICE_EXPORTED: "INVOICE_EXPORTED",
    INVOICE_EXPORTED_FAILED: "INVOICE_EXPORTED_FAILED",
  },
}

export function useNinjaPSAConfigurations() {
  return useSelector(state => state.ninjaPSA.configurations)
}

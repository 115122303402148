import { isValidWindowsPath, isValidUncPath, isNotNilOrEmpty } from "js/includes/common/utils"
import { isArrowImagePlan } from "./services"

export const isValidDestination = destination =>
  destination === "original" || isValidWindowsPath(destination) || isValidUncPath(destination)

export const validateBackupPlans = (backupPlans = {}) => {
  const arrowPlan = Object.values(backupPlans).find(({ type }) => isArrowImagePlan(type))
  if (!arrowPlan) return true

  return isNotNilOrEmpty(arrowPlan?.general?.destination)
}

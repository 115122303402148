import { Modal, Checkbox, Select, Text, AlertMessage } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"
import { useForm } from "js/includes/common/hooks"
import {
  AppleCustomAppType,
  getAppleAppName,
  localizationKey,
  localized,
  localizedWith,
  validations,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import {
  ApplePolicyAppLicenseType,
  ApplePolicyAppsInstallTypes,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

export const installTypeOptions = [
  { value: ApplePolicyAppsInstallTypes.INSTALLED, labelToken: localizationKey("Forced Installed") },
  {
    value: ApplePolicyAppsInstallTypes.BLOCKED,
    labelToken: localizationKey("Blocked"),
  },
]

export const BlockedAssignmentInfoAlertBanner = () => (
  <AlertMessage variant="info">
    {localized("The Blocked assignment type is only supported on Company Owned (Supervised) devices.")}
  </AlertMessage>
)

export const ForceInstallOptions = ({ isPolicy, values, onChange, isVPP, disabled }) => {
  return (
    <>
      {!isVPP && (
        <>
          <Checkbox
            label={
              <Text
                size="sm"
                token={localizationKey("Allow user removal")}
                color={{ code: "black", shade: "100" }}
                bold
              />
            }
            name="allowUserUninstallation"
            checked={values.allowUserUninstallation}
            onChange={({ isChecked }) => onChange("allowUserUninstallation", isChecked)}
            disabled={disabled}
          />
          <Box marginLeft={sizer(7)} marginBottom={sizer(3)}>
            <Text size="sm" token={localizationKey("A minimum of iOS 14 is needed to allow this behavior.")} />
          </Box>
        </>
      )}
      <Checkbox
        label={localized("Force management")}
        name="forceManagement"
        checked={values.forceManagement}
        onChange={({ isChecked }) => onChange("forceManagement", isChecked)}
        disabled={isVPP || disabled}
      />
      <Box marginLeft={sizer(7)}>
        <Text
          type="body"
          token={localizationKey(
            "If this app is already installed but not managed, this option will assume management of the app. iOS native apps can only be managed by MDM.",
          )}
          textWrapLineLimit={3}
          textWrap
        />
      </Box>
      <Box marginLeft={sizer(7)} marginTop={sizer(3)}>
        {isPolicy && (
          <Checkbox
            label={localized("Remove app from the device when it's removed from the policy.")}
            name="removeAppOnPolicyRemoval"
            checked={values.removeAppOnPolicyRemoval}
            onChange={({ isChecked }) => onChange("removeAppOnPolicyRemoval", isChecked)}
            disabled={!values.forceManagement || disabled}
          />
        )}
        <Box marginTop={sizer(3)}>
          <Checkbox
            label={localized("Remove app when device is unenrolled in MDM")}
            name="removeAppOnUnenrollment"
            checked={values.removeAppOnUnenrollment}
            onChange={({ isChecked }) => onChange("removeAppOnUnenrollment", isChecked)}
            disabled={!values.forceManagement || disabled}
          />
        </Box>
      </Box>
    </>
  )
}

export default function InstallationMethodModal({ unmount, country, selectedApp, onSelectMethod, type, isVPP }) {
  const isPolicy = type === "POLICY"
  const isCustomApp = selectedApp.customApp === AppleCustomAppType.CUSTOM
  const { values, onChange, validateForm } = useForm({
    fields: {
      installType: isPolicy && !isCustomApp ? "" : ApplePolicyAppsInstallTypes.INSTALLED,
      allowUserUninstallation: false,
      forceManagement: true,
      removeAppOnPolicyRemoval: false,
      removeAppOnUnenrollment: isCustomApp,
      licenseType: isVPP ? ApplePolicyAppLicenseType.VPP : ApplePolicyAppLicenseType.STORE,
    },
    validate: {
      installType: validations.required,
    },
  })

  return (
    <Modal
      size="sm"
      {...{ unmount }}
      titleGroup={{
        titleText: localized("Add app"),
        descriptionText: isPolicy
          ? localized("Before you can add “{{appName}}” to your policy you will need to select an assignment type.", {
              appName: getAppleAppName(selectedApp),
            })
          : localizedWith(
              "You are choosing to <%boldText>force install<%> “<%appName>appName<%>” onto a device outside of its MDM policy. Adjust the settings below in order to define the removal app behavior.",
              {
                appName: () => selectedApp.name,
                boldText: ({ localizedText }) => <strong>{localizedText}</strong>,
              },
            ),
      }}
      buttons={[
        {
          type: "save",
          disabled: isPolicy && !values.installType,
          labelToken: isPolicy ? localizationKey("Add") : localizationKey("Install"),
          onClick: () => {
            if (validateForm()) {
              onSelectMethod?.({
                applicationId: selectedApp.id,
                bundleId: selectedApp.bundleId ?? selectedApp.id.toString(),
                country,
                customApp: isCustomApp ? AppleCustomAppType.CUSTOM : AppleCustomAppType.PUBLIC,
                ...values,
              })
              unmount()
            }
          },
        },
      ]}
    >
      <>
        {isCustomApp && (
          <Box marginBottom={spacing[4]}>
            <AlertMessage
              variant="danger"
              labelToken={localizationKey(
                "This app can only be removed when a device is fully unenrolled from NinjaOne.",
              )}
            />
          </Box>
        )}
        {isPolicy && (
          <Select
            labelId="select-assignment-type"
            labelToken={localizationKey("Assignment Type")}
            value={values.installType}
            disabled={isCustomApp}
            onChange={assignmentType => {
              assignmentType === ApplePolicyAppsInstallTypes.INSTALLED &&
                onChange({
                  removeAppOnPolicyRemoval: true,
                  forceManagement: true,
                  allowUserUninstallation: false,
                  removeAppOnUnenrollment: false,
                })
              onChange("installType", assignmentType)
            }}
            options={installTypeOptions}
            labelRenderer={() => {
              const selectedValue =
                installTypeOptions.find(option => option.value === values.installType)?.labelToken ?? ""
              return (
                <Box textAlign="left">
                  <Text size="sm" textWrap={true} textWrapLineLimit={1}>
                    {selectedValue ? localized(selectedValue) : localized("Select")}
                  </Text>
                </Box>
              )
            }}
          />
        )}
        <Box marginTop={sizer(isPolicy ? 3 : 0)} minHeight="240px">
          {values.installType === ApplePolicyAppsInstallTypes.INSTALLED && (
            <ForceInstallOptions {...{ values, isPolicy, onChange, isVPP, disabled: isCustomApp }} />
          )}
          {values.installType === ApplePolicyAppsInstallTypes.BLOCKED && <BlockedAssignmentInfoAlertBanner />}
        </Box>
      </>
    </Modal>
  )
}

import PropTypes from "prop-types"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { getTextSize, focusOnDomElement, sizer } from "@ninjaone/utils"
import * as ninjaIcons from "@ninjaone/icons"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"

const accessiblyHiddenUnlessFocused = css`
  &:not(:focus-within) {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }
`

const containerStyle = theme => css`
  position: fixed;
  top: ${sizer(4)};
  left: ${sizer(4)};
  width: 180px;
  border-radius: 4px;
  background: ${theme.color.background};
  border: 2px solid ${theme.color.black["015"]};
  padding: ${sizer(2)};
  z-index: 9999;

  p {
    font-size: ${getTextSize("xs")};
    color: ${theme.color.black["065"]};
    line-height: 1.75;
    font-weight: 600;
    margin-bottom: ${sizer(1)};
    margin-left: ${sizer(2)};
  }
`

const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
  gap: ${sizer(2)};
  height: 32px;
  padding-left: ${sizer(2)};
  padding-right: ${sizer(2)};
  font-size: ${getTextSize("sm")};
  border-radius: 4px;

  && {
    color: ${({ theme }) => theme.color.black["100"]};
    text-decoration: none;
  }

  svg {
    color: ${({ theme }) => theme.color.black["065"]};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const SkipNav = ({ items }) => {
  return (
    <nav
      aria-label={localized("Skip navigation")}
      css={[containerStyle, accessiblyHiddenUnlessFocused]}
      data-testid="skip-nav"
    >
      <p>{localized("Skip to")}:</p>
      <div>
        {items.map(({ label, id, iconName, ref }) => {
          const Icon = ninjaIcons[iconName]

          return (
            <StyledAnchor
              key={id}
              href={`#${id}`} // not used but needed to be a valid <a> (<button> could be used but <a> is more semantically suited for internal navigation)
              onClick={event => {
                event.preventDefault()

                focusOnDomElement(ref.current)
              }}
            >
              {Icon ? <Icon /> : null}
              {label}
            </StyledAnchor>
          )
        })}
      </div>
    </nav>
  )
}

export default SkipNav

SkipNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      iconName: PropTypes.string,
    }),
  ),
}

import { all, allPass, compose, filter, find, includes, prop, propEq } from "ramda"

import {
  getNodeClasses,
  isNilOrEmpty,
  isNotNil,
  isNotNilOrEmpty,
  isSystemAdmin,
  user,
  userProp,
} from "js/includes/common/utils"
import { ACCESS, VIEW } from "js/includes/editors/User/Permissions/common"

const checkAccess = ({ entityType, entityId, permissionName: name }) => {
  if (isSystemAdmin()) return true
  const permissions = userProp("permissions")
  if (isNilOrEmpty(permissions)) return false

  const filteredPermissionsByName = filter(allPass([propEq("entityType", entityType), propEq("name", name)]))(
    permissions,
  )
  if (isNilOrEmpty(filteredPermissionsByName)) return null

  const permissionById = find(propEq("entityId", entityId))(filteredPermissionsByName)
  if (permissionById) return permissionById.allowed

  return find(propEq("entityId", 0))(filteredPermissionsByName)?.allowed ?? null
}

export const isGranted = permission => {
  return !!checkAccess(permission)
}

export const isGrantedForAtLeastOneEntity = ({ entityType, permissionName: name }) => {
  if (isSystemAdmin()) return true
  const permissions = userProp("permissions")
  if (isNilOrEmpty(permissions)) return false

  const matchedPermission = find(
    allPass([propEq("entityType", entityType), propEq("name", name), propEq("allowed", true)]),
  )(permissions)

  return isNotNil(matchedPermission)
}

export const isGrantedForAllEntities = ({ entityType, permissionName: name }) => {
  if (isSystemAdmin()) return true
  const permissions = userProp("permissions")
  if (isNilOrEmpty(permissions)) return false

  const permissionNames = typeof name === "string" ? [name] : name
  const entityPermissions = filter(
    allPass([propEq("entityType", entityType), item => includes(prop("name", item), permissionNames)]),
  )(permissions)

  if (isNilOrEmpty(entityPermissions)) return false

  const nonAllowedPermissions = filter(propEq("allowed", false), entityPermissions)
  const defaultPermission = filter(propEq("entityId", 0), entityPermissions)

  return isNilOrEmpty(nonAllowedPermissions) && isNotNilOrEmpty(defaultPermission)
}

export const isNodeGranted = ({ permissionName, nodeClass, nodeRoleId }) => {
  if (isSystemAdmin()) return true
  const rolePermission = checkAccess({
    entityType: "NODE",
    permissionName: `Role.${permissionName}`,
    entityId: nodeRoleId,
  })
  if (rolePermission !== null) return rolePermission

  const classPermission = checkAccess({
    entityType: "NODE",
    permissionName: `Class.${permissionName}`,
    entityId: compose(prop("id"), find(propEq("name", nodeClass)))(getNodeClasses()),
  })
  if (classPermission !== null) return classPermission

  return isGranted({
    entityType: "NODE",
    permissionName,
    entityId: 0,
  })
}

export const isNodeGrantedForAtLeastOneEntity = ({ permissionName }) => {
  if (isSystemAdmin()) return true
  return (
    isGrantedForAtLeastOneEntity({ entityType: "NODE", permissionName: `Role.${permissionName}` }) ||
    isGrantedForAtLeastOneEntity({ entityType: "NODE", permissionName: `Class.${permissionName}` }) ||
    isGrantedForAtLeastOneEntity({ entityType: "NODE", permissionName })
  )
}

export const hasDeviceAdministrationAccess = () => {
  return isGranted({ entityType: "SYSTEM", permissionName: "Device.Administration.Access" })
}

export const hasDeviceSecureFieldsAccess = () => {
  return isGranted({ entityType: "NODE", permissionName: "SecureFields.Access" })
}

export const isUserAllowedToAccessSecureFields = entityType =>
  entityType === "DOCUMENT" ? hasDocumentationManagementAccess(`SecureFields.${ACCESS}`) : hasDeviceSecureFieldsAccess()

export const hasDocumentationConfigurationAccess = () => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: "Configuration.Access" })
}

export const hasDocumentTemplatePermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `Template.${permission}` })
}

const checkDocumentationManagementPermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `Management.${permission}` })
}

export const hasDocumentationManagementAccess = permission => {
  return checkDocumentationManagementPermission(permission)
}

export const hasPermissionToUpdateDevices = all(({ nodeClass, nodeRoleId }) => {
  return user("canUpdateDevices", { nodeClass, nodeRoleId })
})

const checkRelatedItemPermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `RelatedItem.${permission}` })
}

export const hasRelatedItemsAccess = permission => {
  return checkRelatedItemPermission(permission)
}

export const checkDocumentationAnyKnowledgeBaseManagementPermission = permission => {
  return (
    hasDocumentationGlobalKnowledgeBaseManagementAccess(permission) ||
    (hasDocumentationManagementAccess(permission) && hasOrgViewPermission())
  )
}

const checkDocumentationGlobalKnowledgeBaseManagementPermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `Global.KB.Management.${permission}` })
}

export const hasDocumentationGlobalKnowledgeBaseManagementAccess = permission => {
  return checkDocumentationGlobalKnowledgeBaseManagementPermission(permission)
}

export const hasDocumentationKnowledgeBaseManagementAccess = (permission, clientId) =>
  clientId
    ? hasDocumentationManagementAccess(permission)
    : hasDocumentationGlobalKnowledgeBaseManagementAccess(permission)

export const hasKnowledgeBaseManagementPublicLinkPermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `KB.Management.Public.Links.${permission}` })
}

export const hasDocumentationTotpAccess = () => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: "TOTP.Code.Access" })
}

export const hasNodeTotpAccess = () => {
  return isGranted({ entityType: "NODE", permissionName: "TOTP.Code.Access" })
}

export const hasClientChecklistPermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `Checklist.Management.${permission}` })
}

export const hasTemplateChecklistPermission = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `Checklist.Template.${permission}` })
}

export const hasSystemEndUserAccess = () => {
  return isGranted({ entityType: "SYSTEM", permissionName: "EndUserSharing.Access" })
}

export const hasGlobalKnowledgeBaseEndUserAccess = permission => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: `Global.KB.EndUser.Folder.${permission}.${ACCESS}` })
}

export const hasFolderRestrictionAccess = () => {
  return isGranted({ entityType: "DOCUMENTATION", permissionName: "KB.Management.Access.Restriction.Access" })
}

export const hasContactUpdatePermission = () => {
  return isGranted({ entityType: "SYSTEM", permissionName: "Contact.Update" })
}

export const hasContactCreatePermission = () => {
  return isGranted({ entityType: "SYSTEM", permissionName: "Contact.Create" })
}

export const hasContactDeletePermission = () => {
  return isGranted({ entityType: "SYSTEM", permissionName: "Contact.Delete" })
}

export const hasOrgViewPermission = () => {
  return isGrantedForAtLeastOneEntity({ entityType: "ORGANIZATION", permissionName: VIEW })
}

export const hasCustomSnmpViewPermission = () => isGranted({ entityType: "SYSTEM", permissionName: "CustomSNMP.View" })

export const hasCustomSnmpUpdatePermission = () =>
  isGranted({ entityType: "SYSTEM", permissionName: "CustomSNMP.Update" })

export const hasCustomSnmpViewOrUpdatePermission = () =>
  hasCustomSnmpViewPermission() || hasCustomSnmpUpdatePermission()

export const hasAgentInstallersManagementPermission = () => {
  if (isSystemAdmin()) return true

  return (
    isGranted({ entityType: "NODE", permissionName: "Create" }) &&
    isGrantedForAtLeastOneEntity({ entityType: "ORGANIZATION", permissionName: "View" })
  )
}

/**
 * Check if user has view permissions for a specific organization.
 * @param {number} entityId - Entity ID of the organization
 * @returns {boolean}
 */
export const canViewOrganization = entityId => {
  return isGranted({
    entityType: "ORGANIZATION",
    permissionName: VIEW,
    entityId,
  })
}

import { useCallback } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import styled from "@emotion/styled"
import { faMoon, faSun, faPalette } from "@fortawesome/pro-light-svg-icons"

import { sizer } from "@ninjaone/utils"
import { IconButton, Dropdown } from "@ninjaone/components"

import {
  isSystemAdmin,
  isUserAllowedToCreateTicket,
  user,
  isAppInDevMode,
  isAppInDevDesignInstance,
  localized,
} from "js/includes/common/utils"
import AddDropdown from "js/includes/components/AddDropdown"
import AppsDropdown from "js/includes/components/AppsDropdown"
import HelpDropdown from "js/includes/components/HelpDropdown/HelpDropdown"
import { Flex } from "js/includes/components/Styled"
import UserDropdown from "js/includes/components/UserDropdown"

import TrialNavBar from "./TrialCountdown/TrialCountdown"
import { setDarkMode, setAppTheme } from "js/state/actions/websiteBranding/brandingNode"

const StyledDivider = styled.div`
  box-sizing: border-box;
  margin-left: ${sizer(2)};
  width: 1px;
  border: ${({ theme }) => `1px solid ${theme.color.divider}`};
`

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`

function showDarkModeToggle() {
  return (
    (isAppInDevDesignInstance() || isAppInDevMode()) && window.localStorage.getItem("showDarkModeToggle") === "true"
  )
}

function TopNavBar({ isTrialLicense, fixedHeight }) {
  const dispatch = useDispatch()
  const darkMode = useSelector(state => state.application.darkMode)

  const handleDarkModeToggle = useCallback(() => {
    dispatch(setDarkMode(!darkMode))
  }, [dispatch, darkMode])

  const handleThemeChange = useCallback(theme => dispatch(setAppTheme(theme.value)), [dispatch])

  const showAddDropdown =
    isUserAllowedToCreateTicket() ||
    user("canCreateDevices") ||
    user("canCreateUsers") ||
    (user("canManageReports") && user("canViewAtLeastOneOrganization")) ||
    user("canCreateCustomers") ||
    user("canCreatePolicies") ||
    user("canCreateScheduledTasks")

  return (
    <StyledNav {...{ id: "application-topbar", fixedHeight, ariaLabel: localized("Top bar") }}>
      {isTrialLicense && (
        <Flex>
          <TrialNavBar />
          <StyledDivider />
        </Flex>
      )}
      <Flex gridColumnGap={sizer(1)}>
        {/* Only show when in a dev design environment or localhost and has showDarkModeButton local storage key */}
        {showDarkModeToggle() && (
          <>
            <Dropdown
              onSelect={handleThemeChange}
              options={[
                {
                  labelToken: "Ninja Blue",
                  value: "theme-ninja-blue",
                },
                {
                  labelToken: "Light Grey",
                  value: "theme-light-grey",
                },
                {
                  labelToken: "Dark Blue",
                  value: "theme-dark-grey",
                },
                {
                  labelToken: "One",
                  value: "theme-one",
                },
                {
                  labelToken: "Two",
                  value: "theme-two",
                },
                {
                  labelToken: "Three",
                  value: "theme-three",
                },
                {
                  labelToken: "Four",
                  value: "theme-four",
                },
                {
                  labelToken: "Five",
                  value: "theme-five",
                },
                {
                  labelToken: "Six",
                  value: "theme-six",
                },
              ]}
            >
              <IconButton renderAsDiv icon={faPalette} tooltip="Change theme" />
            </Dropdown>
            <IconButton
              onClick={handleDarkModeToggle}
              icon={darkMode ? faSun : faMoon}
              tooltip={darkMode ? "Toggle light mode" : "Toggle dark mode"}
              size="lg"
            />
          </>
        )}
        {showAddDropdown && <AddDropdown />}
        {isSystemAdmin() && <AppsDropdown />}
        <HelpDropdown portalContainerId="application" />
        <UserDropdown />
      </Flex>
    </StyledNav>
  )
}

export default connect(({ session }) => ({
  isTrialLicense: session.isTrialLicense,
}))(TopNavBar)

const criteriaDefinitions = [
  {
    name: "os-full-name",
    // allValues: "OS (with edition)",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "os",
    // allValues: "OS",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "memory-capacity",
    // units: "GB",
    defaults: {
      operation: "lt",
    },
  },
  {
    name: "processor-type",
    // allValues: "processors",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "contains-text",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "device-name",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "device-make",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "node-teamviewer-id",
    defaults: {
      selectedValues: {},
    },
  },
  {
    name: "node-policies",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "node-role",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "customer",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "location",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "software-inventory",
    // allValues: "softwareInventory",
    defaults: {
      selectedValues: [],
      contain: "do",
      include: "all",
      timespanMode: "all",
    },
  },
  {
    name: "last-patch-scan",
    defaults: { timespanMode: "range" },
  },
  {
    name: "no-patch-scan",
    defaults: { timespanMode: "withinTheLast" },
  },
  {
    name: "last-installed-patch",
    defaults: { timespanMode: "range", failuresOnly: false, ninjaUpdatesOnly: true },
  },
  {
    name: "patch-inventory",
    defaults: {
      contain: true,
      selectedValues: [],
      //     {
      //       name: '2018-04 Cumulative Update for Windows 10 Version 1703 for x64-based Systems (KB4093117)',
      //       mode: 'known'
      //     },
      patchStatuses: [],
      //     'AVAILABLE',
      //     'MANUAL',
      //     'APPROVED',
      //     'REJECTED',
      //     'INSTALLED',
      //     'FAILED'
    },
  },
  {
    name: "patch-software-inventory",
    defaults: {
      contain: true,
      selectedValues: [],
      patchStatuses: [],
    },
  },
  {
    name: "node-status",
    // allValues: "nodeStatuses",
    defaults: {
      selectedValues: [],
      include: "some",
    },
  },
  {
    name: "node-type",
    // allValues: "nodeTypes",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "drive-total-space",
    // units: "GB",
    defaults: {
      operation: "lt",
    },
  },
  {
    name: "volume-free-space",
    // units: "GB",
    defaults: {
      operation: "lt",
    },
  },
  {
    name: "activities",
    // allValues: "activityStatuses",
    defaults: {
      selectedValues: [],
      timespanMode: "all",
    },
  },
  {
    name: "needs-reboot",
    defaults: {
      rebootCauses: [],
    },
  },
  {
    name: "logged-in-user",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "domain",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "dns-server",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "gateway-ip",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "ip-address",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "public-ip",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "notes",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "node-reliability-uptime",
    defaults: {
      timespanMode: "withinTheLast",
    },
  },
  {
    name: "node-reliability-last-uptime",
    defaults: {
      timespanMode: "withinTheLast",
    },
  },
  {
    name: "node-reliability-offline",
    defaults: {
      timespanMode: "withinTheLast",
    },
  },
  {
    name: "any-condition-triggered",
    defaults: {},
  },
  {
    name: "condition-triggered",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "node-approval-status-pending",
    defaults: {},
  },
  {
    name: "node-approval-status-approved",
    defaults: {},
  },
  {
    name: "devices-with-install-issues",
    defaults: {},
  },
  {
    name: "devices-requiring-install-reboot",
    defaults: {
      rebootReasons: ["BDRC_REBOOT_INSTALL_FAIL"],
    },
  },
  {
    name: "devices-requiring-reboot-upgrade-engine",
    defaults: {
      rebootReasons: ["BDRC_REBOOT_PROD_UPDATE", "BDRC_REBOOT_INSTALL_MODIFICATION"],
    },
  },
  {
    name: "devices-requiring-reboot-disinfect",
    defaults: {
      rebootReasons: ["BDRC_REBOOT_DISINFECT"],
    },
  },
  {
    name: "os-build-version",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "os-architecture",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "bitlocker-status",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "fileVault-status",
    defaults: {},
  },
  {
    name: "tpm-status",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "maintenance-mode",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "expired-apple-enrollment-profile-status",
    defaults: {},
  },
  {
    name: "mdm-apn-connection",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "mdm-android-enterprise-connection",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "computer-system-roles",
    defaults: {
      selectedValues: ["DOMAIN_CTRL", "DOMAIN_BAKCTRL"],
    },
  },
  {
    name: "custom-fields",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "device-threats",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "os-patching-enabled",
    defaults: {},
  },
  {
    name: "windows-patching-mode",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "device-running-action",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "software-patching-enabled",
    defaults: {},
  },
  {
    name: "os-type",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "os-minor-version",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "os-major-version",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "os-release-version",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "warranty-start-date",
    defaults: {
      timeUnit: "month",
      mode: "gte",
      amount: 0,
    },
  },
  {
    name: "warranty-end-date",
    defaults: {
      timeUnit: "month",
      mode: "gte",
      amount: 0,
    },
  },
  {
    name: "manufacturer-fulfillment-date",
    defaults: {
      timeUnit: "month",
      mode: "gte",
      amount: 0,
    },
  },
  {
    name: "nm-delegate",
    defaults: {},
  },
  {
    name: "os-install-date",
    defaults: {
      timeUnit: "month",
      mode: "gte",
      amount: 0,
    },
  },
  {
    name: "ninja-backup-enabled",
    defaults: {},
  },
  {
    name: "ninja-backup-agent-installed",
    defaults: {},
  },
  {
    name: "ninja-backup-plan-type",
    defaults: {},
  },
  {
    name: "vulnerability-scan-group-name",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "vulnerability-severity",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "vulnerability-cve-id",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "mdm-android-enterprise-connection",
    defaults: {
      selectedValues: [],
    },
  },
  {
    name: "mdm-apn-connection",
    defaults: {
      selectedValues: [],
    },
  },
]

export default criteriaDefinitions

import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react"

export const ThemeProvider = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState(initialTheme)

  const themeName = useSelector(state => state.application.theme)
  const darkMode = useSelector(state => state.application.darkMode)

  const getTheme = useCallback(async () => {
    const module = await import(`js/includes/common/theme/${themeName}/${darkMode ? "dark.js" : "light.js"}`)
    window.theme = module.default
    setTheme(module.default)
  }, [themeName, darkMode])

  useEffect(() => {
    getTheme()
  }, [getTheme])

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
}

import React, { PureComponent } from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import clsx from "clsx"
import {
  faGaugeHigh,
  faComputer,
  faUserHeadset,
  faGear,
  faAnglesLeft,
  faAnglesRight,
  faPieChart,
  faRocket,
  faClose,
  faUsers,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { sizer } from "@ninjaone/utils"
import { Modal, Tooltip, resetButtonStyle } from "@ninjaone/components"
import {
  localized,
  ninjaReportError,
  user,
  isUserAllowedToViewTicketNavigation,
  routeStartsWith,
  getAppLogoFromBranding,
  getBrowserIconFromBranding,
  isFeatureEnabled,
  isNinjaPSAEnabledFromSettings,
  getLocalStorageParsedObject,
  fetchJson,
  localizationKey,
  ninjaImages,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
} from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { faNinjaControl } from "media/icons"
import RecentTabs from "./RecentTabs"
import FavoriteTabs from "./FavoriteTabs"
import BoardsTabs from "./BoardsTabs"
import FinanceTabs from "./FinanceTabs"
import MainNavLink from "./MainNavLink"
import VersionInfo from "./VersionInfo"
import { getLocalStorageKey, sidebarWidth, sidebarWidthCollapsed } from "./common"
import showModal from "js/includes/common/services/showModal"
import { handleOpenGuideTooltip } from "js/state/actions/general/guideTooltips"

const StyledSideBarLogo = styled.span`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${sizer(4)};

  img {
    max-width: 180px;
    max-height: ${({ isUsingNinjaLogo }) => (isUsingNinjaLogo ? "26px" : "30px")};
  }

  a {
    ${({ isUsingNinjaLogo, collapsed }) => isUsingNinjaLogo && !collapsed && `padding: 2px 0;`}

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
      outline-offset: -2px;
    }
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 18px;

    img {
      max-width: 20px;
      padding-left: 0;
    }
  `}
`

const StyledSideBarMinimize = styled.button`
  ${resetButtonStyle}
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? "center" : "flex-end")};
  height: 38px;
  margin-top: auto;
  cursor: pointer;
  font-size: 15px;
  padding-right: ${sizer(3)};
  padding-left: ${sizer(3)};

  && {
    color: ${({ theme }) => theme.colorThemeText};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

const sidebarStyle = theme => css`
  display: flex;
  flex-direction: column;
  width: ${sidebarWidth}px;
  height: 100vh;
  background-color: ${theme.colorThemeBackground};

  &.collapsed {
    width: ${sidebarWidthCollapsed}px;
  }
`

const dividerStyle = theme => css`
  height: 1px;
  background-color: ${theme.colorThemeBorder};
  margin-top: ${sizer(3)};
  margin-bottom: ${sizer(3)};
`

const collapsedLocalStorageKey = getLocalStorageKey("collapsed")
const getCollapsedFromLocalStorage = () => getLocalStorageParsedObject(collapsedLocalStorageKey)
const getIsAdministrationRoute = route => route.startsWith("#/administration")
const getIsViewportAtCollapseWidth = () => window.innerWidth < 1440

class SideNavBar extends PureComponent {
  state = {
    version: {},
    showVersion: false,
    activeRoute: window.location.hash,
  }
  toggleNavigationWidth = () => {
    this.props.setIsCollapsedSidebar(collapsed => {
      window.localStorage.setItem(collapsedLocalStorageKey, !collapsed)

      return !collapsed
    })
  }

  showVersionInfo = async e => {
    if (e.ctrlKey && !this.state.showVersion) {
      try {
        const version = await window.application.get("version").fetch()

        this._isMounted &&
          this.setState({
            showVersion: true,
            version,
          })
      } catch (error) {
        ninjaReportError(error)
      }
    }
  }
  hideVersionInfo = () => {
    if (this.state.showVersion) {
      this.setState({
        showVersion: false,
        version: {},
      })
    }
  }

  ModalRemoveGettingStarted = ({ unmount }) => (
    <Modal
      titleGroup={{
        titleToken: localizationKey("Hide page?"),
        descriptionToken: localizationKey(
          "If you change your mind, you have the option to add the Get Started page back from User Settings > Display.",
        ),
      }}
      buttons={[
        {
          labelToken: localizationKey("Hide page"),
          variant: "primary",
          onClick: () => {
            fetchJson("/appuser/content/onboarding-performed/true", { options: { method: "PATCH" } })
            const user = { ...this.props.user }
            user.content.onboardingPerformed = true
            window.store.dispatch({ type: "SET_USER", user })
            window.location.hash = "/systemDashboard/overview"

            setTimeout(() => {
              // help tooltip will hide on hashchange
              // we need to wait until after the hashchange to show it
              window.store.dispatch(handleOpenGuideTooltip("helpMenu"))
            }, 0)

            unmount()
          },
        },
      ]}
      unmount={unmount}
      cancelable
    />
  )

  removeGettingStartedPage = e => {
    e.preventDefault()
    e.stopPropagation()
    const { ModalRemoveGettingStarted } = this
    showModal(<ModalRemoveGettingStarted />)
  }

  handleSidebarCollapse = activeRoute => {
    const isAdministrationRoute = getIsAdministrationRoute(activeRoute)
    const collapsed = getCollapsedFromLocalStorage()
    const isViewPortAtCollapsedWidth = getIsViewportAtCollapseWidth()

    this.props.setIsCollapsedSidebar(isViewPortAtCollapsedWidth ? true : collapsed ?? isAdministrationRoute)
  }

  onRouteChange = () => {
    const activeRoute = window.location.hash
    this.handleSidebarCollapse(activeRoute)
    this.setState(() => ({
      activeRoute,
    }))
  }

  onWindowRezise = () => {
    const { activeRoute } = this.state
    this.handleSidebarCollapse(activeRoute)
  }

  componentDidMount() {
    this._isMounted = true

    const { activeRoute } = this.state
    this.handleSidebarCollapse(activeRoute)

    window.addEventListener("hashchange", this.onRouteChange)
    window.addEventListener("resize", this.onWindowRezise)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener("hashchange", this.onRouteChange)
    window.removeEventListener("resize", this.onWindowRezise)
  }

  render() {
    const {
      props: {
        remoteSupport,
        ninjaPSAEnabled,
        user: userFromProps,
        divisionConfig,
        ticketingEnabled,
        quickConnectEnabled,
        isCollapsedSidebar,
      },
      state: { activeRoute, version, showVersion },
      showVersionInfo,
      hideVersionInfo,
      toggleNavigationWidth,
      removeGettingStartedPage,
    } = this

    const { onboardingPerformed } = userFromProps?.content ?? {}
    const appLogo = getAppLogoFromBranding()
    const isUsingNinjaLogo = [ninjaImages["appLogo"], ninjaImages["appLogoDark"]].includes(appLogo)
    const browserIcon = getBrowserIconFromBranding()
    const isRemoteSupportIntegrationEnabled = remoteSupport.enabled
    const ninjaRemoteEnabled = divisionConfig?.find(item => item.name === "NINJAREMOTE")?.enabled

    const GetStartedLink = (
      <MainNavLink
        to="/getStarted"
        isActive={routeStartsWith("/getStarted")}
        icon={faRocket}
        label={localized("Get started")}
        collapsed={isCollapsedSidebar}
        actionIcons={[
          {
            element: <FontAwesomeIcon icon={faClose} size="sm" />,
            tooltip: localized("Hide page"),
            sideOffset: isCollapsedSidebar ? 32 : 36,
            onClick: removeGettingStartedPage,
            id: "fs-remove-getting-started-page-button",
          },
        ]}
      />
    )

    const SystemDashboardLink = (
      <MainNavLink
        to="/systemDashboard/overview"
        isActive={routeStartsWith("/systemDashboard")}
        icon={faGaugeHigh}
        label={localized("Dashboard")}
        collapsed={isCollapsedSidebar}
      />
    )

    const DeviceSearchLink = (
      <MainNavLink
        to="/deviceSearch"
        isActive={routeStartsWith(["/deviceSearch"])}
        icon={faComputer}
        label={localized("Devices")}
        collapsed={isCollapsedSidebar}
      />
    )

    const EndUserSearchLink = (
      <MainNavLink
        to="/endUserSearch"
        isActive={routeStartsWith(["/endUserSearch"])}
        icon={faUsers}
        label={localized("End users")}
        collapsed={isCollapsedSidebar}
      />
    )

    const RemoteSupportLink = (
      <MainNavLink
        to="/remoteSupport"
        icon={faUserHeadset}
        label={localized("Remote Support")}
        collapsed={isCollapsedSidebar}
      />
    )

    const QuickConnectLink = (
      <MainNavLink
        to="/quickConnect"
        isActive={routeStartsWith(["/quickConnect"])}
        icon={faNinjaControl}
        label={localized("Quick Connect")}
        collapsed={isCollapsedSidebar}
      />
    )

    const AdministrationLink = (
      <MainNavLink
        to={user("canConfigureSystemActivities") ? user("getGeneralTabLink") : "/administration/users/technicians"}
        isActive={routeStartsWith("/administration")}
        icon={faGear}
        label={localized("Administration")}
        collapsed={isCollapsedSidebar}
      />
    )

    const ReportingLink = (
      <MainNavLink
        to="/reporting/reports"
        isActive={routeStartsWith(["/reporting"])}
        icon={faPieChart}
        label={localized("Reporting")}
        collapsed={isCollapsedSidebar}
      />
    )

    const showRemoteSupport =
      user("canUseRemoteSupport") && isRemoteSupportIntegrationEnabled && isFeatureEnabled("remotesupport")
    const showQuickConnect = ninjaRemoteEnabled && quickConnectEnabled && user("canConfigureQuickConnect")
    const showReporting = user("canViewAndScheduleReports") && user("canViewAtLeastOneOrganization")
    const minimizeText = isCollapsedSidebar ? localized("Expand menu") : localized("Collapse menu")

    return (
      <aside
        id="application-sidebar"
        className={clsx({ collapsed: isCollapsedSidebar })}
        css={sidebarStyle}
        data-testid="application-sidebar"
      >
        <StyledSideBarLogo
          onMouseEnter={showVersionInfo}
          onMouseLeave={hideVersionInfo}
          collapsed={isCollapsedSidebar}
          isUsingNinjaLogo={isUsingNinjaLogo}
        >
          <Link to="/systemDashboard/overview">
            <img src={appLogo} alt="logo" hidden={isCollapsedSidebar} />
            <img src={browserIcon} alt="logo" hidden={!isCollapsedSidebar} />
          </Link>
        </StyledSideBarLogo>

        {showVersion && <VersionInfo {...{ version, collapsed: isCollapsedSidebar }} />}

        <Box as="nav" overflowY="auto" overflowX="hidden" aria-label={localized("Main navigation")}>
          <>
            <Flex flexDirection="column" gap={sizer(1)}>
              {!onboardingPerformed && GetStartedLink}
              {SystemDashboardLink}
              {DeviceSearchLink}
              {user("canCRUDEndUserSharing") && EndUserSearchLink}
              {showRemoteSupport && RemoteSupportLink}
              {showQuickConnect && QuickConnectLink}
              {showReporting && ReportingLink}
              {ticketingEnabled && isUserAllowedToViewTicketNavigation() && (
                <BoardsTabs
                  {...{
                    activeRoute,
                    collapsed: isCollapsedSidebar,
                  }}
                />
              )}
              {ninjaPSAEnabled && isUserAllowedToUseNinjaPSAAdministrativeActions() && isNinjaPSAEnabledFromSettings() && (
                <FinanceTabs
                  {...{
                    activeRoute,
                    collapsed: isCollapsedSidebar,
                  }}
                />
              )}
            </Flex>
            <div css={dividerStyle}></div>
            <Flex flexDirection="column" gap={sizer(2)}>
              {AdministrationLink}
              <FavoriteTabs
                {...{
                  activeRoute,
                  collapsed: isCollapsedSidebar,
                }}
              />
              <RecentTabs
                {...{
                  activeRoute,
                  collapsed: isCollapsedSidebar,
                }}
              />
            </Flex>
          </>
        </Box>
        <Tooltip asChild sideOffset={8} label={minimizeText} position="right" align="center">
          <StyledSideBarMinimize
            onClick={toggleNavigationWidth}
            collapsed={isCollapsedSidebar}
            aria-label={minimizeText}
          >
            <FontAwesomeIcon icon={isCollapsedSidebar ? faAnglesRight : faAnglesLeft} />
          </StyledSideBarMinimize>
        </Tooltip>
      </aside>
    )
  }
}

const mapStateToProps = ({
  application: { theme },
  general: { recentTabs, favoriteTabs },
  session: { user, divisionConfig },
  ticketing: {
    configurations: { enabled: ticketingEnabled },
  },
  ninjaPSA: {
    configurations: { enabled: ninjaPSAEnabled },
  },
  quickConnect: {
    configurations: { enabled: quickConnectEnabled },
  },
  remoteSupport,
}) => ({
  theme,
  favoriteTabs,
  ninjaPSAEnabled,
  quickConnectEnabled,
  recentTabs,
  remoteSupport,
  user,
  divisionConfig,
  ticketingEnabled,
})

export default connect(mapStateToProps)(SideNavBar)

import { connect } from "react-redux"
import { assoc, compose, map, when } from "ramda"

import { spacing } from "@ninjaone/tokens"

import { AlertMessage, Modal, Select, Text, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"

import { useForm } from "js/includes/common/hooks"
import { AppleCustomAppType, localizationKey, localized, validations } from "js/includes/common/utils"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box, Flex } from "js/includes/components/Styled"
import {
  ApplePolicyAppLicenseType,
  ApplePolicyAppsInstallTypes,
  formatApplicationId,
  ninjaOneAssistAppBundleId,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util.js"
import { ForceInstallOptions, installTypeOptions, BlockedAssignmentInfoAlertBanner } from "./InstallationMethodModal"

const getDefaultSelectedAppValues = (selectedApps, appPolicy) => {
  if (selectedApps.length !== 1) {
    return {}
  } else {
    const { bundleId } = selectedApps[0]
    return appPolicy[formatApplicationId(bundleId)]
  }
}

const disableBlockedOption = option =>
  compose(
    assoc("disabled", true),
    assoc("LabelComponent", () => (
      <Tooltip
        label={localized(
          "NinjaOne Assist App can not be set to blocked install type when location tracking is enabled.",
        )}
      >
        <Flex justifyContent="flex-start" alignItems="baseline" minWidth="470px" width="100%" gap={spacing[2]}>
          <Text type="body" token={localizationKey("Blocked")}></Text>
          <Box>
            <RegularInfoCircleIcon size="sm" />
          </Box>
        </Flex>
      </Tooltip>
    )),
  )(option)

function EditAppModal({ selectedApps, appPolicy, findDevice, unmount, parentPolicy, updatePolicyItem }) {
  const defaultSelectedApp = getDefaultSelectedAppValues(selectedApps, appPolicy)
  const isCustomApp = selectedApps[0].customApp === AppleCustomAppType.CUSTOM
  const isVPP = selectedApps[0].licenseType === ApplePolicyAppLicenseType.VPP
  const { values, onChange, validateForm } = useForm({
    fields: {
      installType: defaultSelectedApp.installType ?? installTypeOptions[0].value,
      allowUserUninstallation: defaultSelectedApp.allowUserUninstallation ?? false,
      forceManagement: defaultSelectedApp.forceManagement ?? false,
      removeAppOnPolicyRemoval: defaultSelectedApp.removeAppOnPolicyRemoval ?? false,
      removeAppOnUnenrollment: defaultSelectedApp.removeAppOnUnenrollment ?? false,
    },
    validate: {
      installType: validations.required,
    },
  })

  const shouldDisableBlockedOptionForN1A =
    findDevice?.generalSettings.enabled && selectedApps.some(app => app.bundleId === ninjaOneAssistAppBundleId)

  const handleSaveChanges = () => {
    if (!validateForm()) return
    for (const { bundleId } of selectedApps) {
      const uniqueId = formatApplicationId(bundleId)
      const newApp = {
        ...appPolicy[uniqueId],
        ...values,
        allowUserUninstallation:
          values.assignmentType === ApplePolicyAppsInstallTypes.BLOCKED ? false : values.allowUserUninstallation,
      }
      updatePolicyItem(`application.applications.${uniqueId}`, parentPolicy, newApp)
    }
    unmount()
  }

  return (
    <Modal
      size="sm"
      titleGroup={{
        titleToken: localized("Edit {{appName}} Assignment Type", {
          appName: selectedApps?.length !== 1 ? localized("App(s)") : selectedApps[0]?.name || localized("Application"),
        }),
      }}
      unmount={unmount}
      cancelable
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Save"),
          onClick: handleSaveChanges,
          disabled: !values.installType,
        },
      ]}
    >
      <Box>
        {isCustomApp && (
          <Box marginBottom={spacing[4]}>
            <AlertMessage
              variant="danger"
              labelToken={localizationKey(
                "This app can only be removed when a device is fully unenrolled from NinjaOne.",
              )}
            />
          </Box>
        )}

        <form>
          <Select
            labelId="edit-assignment-type"
            labelToken={localizationKey("Assignment Type")}
            value={values.installType}
            onChange={e => onChange("installType", e)}
            options={
              shouldDisableBlockedOptionForN1A
                ? map(
                    when(
                      option => option.value === ApplePolicyAppsInstallTypes.BLOCKED,
                      option => disableBlockedOption(option),
                    ),
                    installTypeOptions,
                  )
                : installTypeOptions
            }
            disabled={isCustomApp}
          />
          <Box marginTop={spacing[3]} minHeight="240px">
            {values.installType === ApplePolicyAppsInstallTypes.INSTALLED && (
              <ForceInstallOptions
                {...{ values, onChange, isVPP: isVPP || isCustomApp, disabled: isCustomApp }}
                isPolicy
              />
            )}
            {values.installType === ApplePolicyAppsInstallTypes.BLOCKED && <BlockedAssignmentInfoAlertBanner />}
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

export default connect(
  ({ policyEditor }) => ({
    appPolicy: policyEditor.policy.content.application?.applications ?? {},
    findDevice: policyEditor.policy.content.findDevice,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(EditAppModal)

import { useCallback, useEffect } from "react"
import { connect } from "react-redux"
import {
  compose,
  equals,
  filter,
  find,
  map,
  prop,
  uniqWith,
  omit,
  pluck,
  cond,
  isNil,
  always,
  toLower,
  trim,
  T,
  any,
  forEach,
  has,
  values,
} from "ramda"
import { DataTable, Text, TitleGroup } from "@ninjaone/components"
import { PlusIcon } from "@ninjaone/icons"
import { filterTypes } from "@ninjaone/components/src/DataTable"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import { updatePolicyItem, revertPolicySection as _revertPolicySection } from "js/state/actions/policyEditor/editor"
import { getAppleAppName, isNotNil, localizationKey, localized, showSuccessMessage } from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { getIosAppsMetadata, mergeAppMetadataWithBundleId } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { installTypeOptions } from "./ios/applications/InstallationMethodModal"
import EditAppModal from "./ios/applications/EditAppModal"
import DeleteAppModal from "./dialogs/DeleteAppModal"
import {
  getOverrideStateColumn,
  isInheritedRow,
  isNotOverriddenRow,
  defaultInheritance,
  getActiveStatusColumn,
  getStatusActions,
  formatApplicationId,
  ninjaOneAssistAppBundleId,
  ApplePolicyAppLicenseType,
} from "./util"
import { AddIOSAppModal, appTypeLabelTokens } from "./ios/applications/AddIOSAppModal"
import ViewOrganizationsModal from "./ios/applications/ViewOrganizationsModal"

const getColumns = isChildPolicy => [
  {
    id: "name",
    Header: localized("Name"),
    getImg: ({ iconUrl512 }) => (iconUrl512 ? { src: iconUrl512, size: "40" } : null),
    accessor: app => getAppleAppName(app),
  },
  {
    id: "publisher",
    Header: localized("Seller"),
    accessor: "publisher",
  },
  {
    id: "packageId",
    Header: localized("Package ID"),
    accessor: "bundleId",
  },
  {
    id: "formattedPrice",
    Header: localized("Cost"),
    accessor: ({ formattedPrice, currency }) =>
      cond([
        [isNil, always("-")],
        [compose(equals("free"), toLower), always(localized("Free"))],
        [T, always(trim(`${formattedPrice} ${currency || ""}`))],
      ])(formattedPrice),
  },
  {
    id: "minimumOsVersion",
    Header: localized("Minimum iOS support"),
    accessor: ({ minimumOsVersion }) => `iOS ${minimumOsVersion ?? ""}`,
  },
  {
    id: "installType",
    Header: localized("Assignment Type"),
    accessor: ({ installType }) =>
      compose(
        localized,
        prop("labelToken"),
        find(item => item.value === installType),
      )(installTypeOptions),
  },
  {
    id: "licenseType",
    Header: localized("Distribution type"),
    accessor: ({ licenseType }) =>
      licenseType === "VPP" ? localized(appTypeLabelTokens.vpp) : localized(appTypeLabelTokens.store),
  },
  ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
]

const getSingleFilterOptions = (columnId, data) => {
  const availableData = compose(
    filter(item => item.value),
    map(item => ({ value: item?.[columnId], label: item?.[columnId] })),
  )(data)
  return data.length > 0 ? uniqWith(equals)(availableData) : []
}

const getApplicationsFilters = rows => ({
  primary: [
    {
      name: "installType",
      type: filterTypes.SEARCHABLE_SELECT,
      labelToken: localizationKey("Assignment Type"),
      componentProps: {
        options: installTypeOptions.map(item => ({ value: item.value, label: item.labelToken })),
      },
    },
  ],
  secondary: [
    {
      name: "publisher",
      type: filterTypes.SINGLE_SELECT,
      labelToken: localizationKey("Seller"),
      componentProps: {
        options: getSingleFilterOptions("publisher", rows),
      },
    },
    {
      name: "minimumOsVersion",
      type: filterTypes.SINGLE_SELECT,
      labelToken: localizationKey("iOS Support"),
      componentProps: {
        options: getSingleFilterOptions("minimumOsVersion", rows),
      },
    },
  ],
})

const MobileIosApplications = ({
  applications,
  findDevice,
  policyName,
  updateApplication,
  removeApplications,
  parentPolicy,
  revertPolicySection,
}) => {
  const [loading, setLoading] = useMountedState(false)
  const [appsMetadata, setAppsMetadata] = useMountedState([])

  const loadMetadata = useCallback(
    async apps => {
      setLoading(true)
      const data = await getIosAppsMetadata(values(apps))
      setAppsMetadata(data)
      setLoading(false)
    },
    [setAppsMetadata, setLoading],
  )

  useEffect(() => {
    loadMetadata(applications)
  }, [applications, loadMetadata])

  const shouldDisableRemoveForNinjaApp = app =>
    findDevice.generalSettings.enabled && app.bundleId === ninjaOneAssistAppBundleId

  const onAddApp = app => {
    const uniqueId = formatApplicationId(app.bundleId)
    const exists = has(uniqueId, applications)
    if (exists) {
      showSuccessMessage(localized("The selected app is already added to the policy"))
      return
    }

    const newApp = { ...app, ...defaultInheritance, active: true }

    updateApplication(uniqueId, newApp, applications)
    showSuccessMessage(
      localized(`{{appName}} was added to the list`, {
        appName: app.name,
      }),
    )
  }

  const showSearchApp = () => {
    showModal(<AddIOSAppModal {...{ policyName, onAddApp, type: "POLICY" }} showVPP />, {
      withProvider: true,
    })
  }

  const onEditApps = (selectedApps = []) => {
    showModal(<EditAppModal {...{ selectedApps }} />, { withProvider: true })
  }

  const onRemoveApps = (selectedApps = []) => {
    const filterSelectedApps = selectedApps.filter(app => !shouldDisableRemoveForNinjaApp(app))
    showModal(
      <DeleteAppModal
        {...{ selectedApps: filterSelectedApps, osType: "Apple" }}
        appPolicy={{ applications: values(applications) }}
        updateApplicationsPolicy={() => {
          removeApplications(pluck("bundleId", filterSelectedApps), applications, parentPolicy)
        }}
      />,
    )
  }

  const showViewOrganizations = selected => {
    const [appData] = selected
    showModal(<ViewOrganizationsModal {...{ appData }} />)
  }

  const changeAppStatus = app => {
    updateApplication(formatApplicationId(app.bundleId), { ...app, active: app.active === false }, parentPolicy)
  }

  const isChildPolicy = isNotNil(parentPolicy)
  const rows = mergeAppMetadataWithBundleId(values(applications), appsMetadata)
  return (
    <>
      <Box marginLeft={sizer(6)}>
        <Box flexDirection="column" marginBottom={sizer(6)}>
          <TitleGroup
            titleToken={localizationKey("Applications")}
            descriptionToken={localizationKey("Manage and define applications settings.")}
          />
        </Box>
        <Text token={localizationKey("Managed applications")} color={{ code: "black", shade: "100" }} bold />
      </Box>
      <Box marginTop={sizer(4)} marginLeft={sizer(6)} height="100%">
        <DataTable
          {...{ loading, rows, filters: getApplicationsFilters(rows) }}
          isShowSelectedEnabled
          tableId="ios-applications-table"
          columns={getColumns(isChildPolicy)}
          onRefresh={() => loadMetadata(applications)}
          getCustomRowProps={selectedApp => ({
            disabled: shouldDisableRemoveForNinjaApp(selectedApp),
            disabledTooltipLabel: localized(
              "Location tracking must be disabled before NinjaOne Assist can be removed or overriden.",
            ),
          })}
          globalActionsButton={{
            buttonProps: {
              labelToken: localizationKey("Add apps"),
              Icon: PlusIcon,
              action: showSearchApp,
              variant: "secondary",
            },
          }}
          actions={{
            primary: [
              {
                labelToken: localizationKey("Revert overrides"),
                action: ({ selected }) => {
                  const filterSelectedApps = selected.filter(app => !shouldDisableRemoveForNinjaApp(app))
                  return compose(
                    forEach(app =>
                      revertPolicySection(`application.applications.${formatApplicationId(app.bundleId)}`, app),
                    ),
                  )(filterSelectedApps)
                },
                hideMultiAction: any(isNotOverriddenRow),
                hideRowAction: app => isNotOverriddenRow(app) || shouldDisableRemoveForNinjaApp(app),
              },
              ...getStatusActions(changeAppStatus, isChildPolicy, shouldDisableRemoveForNinjaApp),
              {
                labelToken: localizationKey("Edit"),
                action: ({ selected }) => onEditApps(selected),
                hideMultiAction: rows => rows.length > 1,
              },
              {
                labelToken: localizationKey("View organizations"),
                action: ({ selected }) => showViewOrganizations(selected),
                hideRowAction: ({ licenseType }) => licenseType !== ApplePolicyAppLicenseType.VPP,
                hideMultiAction: rows => {
                  if (rows.length > 1) return true
                  const [appData] = rows
                  return appData?.licenseType === ApplePolicyAppLicenseType.STORE
                },
                splitAfter: true,
              },
              {
                labelToken: localizationKey("Remove"),
                action: ({ selected }) => onRemoveApps(selected),
                variant: "danger",
                hideMultiAction: any(isInheritedRow),
                hideRowAction: app => isInheritedRow(app) || shouldDisableRemoveForNinjaApp(app),
                isRed: true,
              },
            ],
          }}
          noRowsToken={localizationKey("No applications found")}
        />
      </Box>
    </>
  )
}

const updateApplication = (uniqueId, app, parentPolicy) =>
  updatePolicyItem(`application.applications.${uniqueId}`, parentPolicy, app)

const removeApplications = (bundleIds, applications, parentPolicy) => dispatch => {
  dispatch({
    type: "UPDATE_POLICY_ITEM",
    pathToItem: "application.applications",
    newItem: omit(map(formatApplicationId, bundleIds), applications),
    parentPolicy: parentPolicy,
  })
}

export default connect(
  ({ policyEditor }) => ({
    applications: policyEditor.policy.content.application?.applications ?? {},
    findDevice: policyEditor.policy.content.findDevice,
    policyName: policyEditor.policy.name,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updateApplication,
    removeApplications,
    revertPolicySection: _revertPolicySection,
  },
)(MobileIosApplications)
